import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";

// import images
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import as3logo from "../../assets/images/as3-engenharia-menor-2.png";

toast.configure();
export default function Login() {
  const [usuario, setUsuario] = useState({ email: "", senha: "" });
  const [isLoading, setLoading] = useState(false);
  const [desabilitaBotao, setDesabilitaBotao] = useState(false);
  const [erroContaBloqueada, setErroContaBloqueada] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  function validateForm() {
    return usuario.email.length > 0 && usuario.senha.length > 0;
  }

  function onChange(event) {
    const { value, name } = event.target;

    setUsuario({
      ...usuario,
      [name]: value,
    });
  }

  async function handleSubmit(event) {
    setDesabilitaBotao(true);
    event.preventDefault();
    try {
      await api.post("/login", usuario).then((response) => {
        localStorage.setItem("authUser", JSON.stringify(response.data.usuario));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userEmail", response.data.usuario.email);
        toast.success("Login realizado com sucesso!", { autoClose: 2000 });
        navigate("/");
      });
    } catch (e) {
      toast.error(e.response.data.message, { autoClose: 3000 });
      setDesabilitaBotao(false);
      setLoading(false);
      setErroContaBloqueada("");
      if (e.response.data.mensagem_bloqueio) {
        setErroContaBloqueada(e.response.data.mensagem_bloqueio);
      }
    }
  }

  const handleClick = () => {
    setLoading(true);
    setEmail(usuario.email);
  };

  function handleEnvioEmail() {
    api
      .post(`reenviar-email-desbloqueio`, { email })
      .then((response) => {
        toast.success(response.data.mensagem_sucesso, { autoClose: 3000 });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          toast.error(error.response.data.mensagem_erro, {
            autoClose: 3000,
          });
        }
      });
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Bem Vindo!</h5>
                        <p>Faça login para acessar a plataforma Microméros</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* Logo do Microméros */}
                  <div className="auth-logo">
                    <div className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  {erroContaBloqueada ? (
                    <>
                      <span className="text-danger">{erroContaBloqueada} </span>
                      <button
                        color="link"
                        className="btn btn-link btn-rounded p-1 mt-n2 ms-n1"
                        onClick={handleEnvioEmail}
                      >
                        Enviar novamente?
                      </button>
                    </>
                  ) : null}
                  <div className="p-2">
                    <Form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <Label for="email">Email</Label>
                        <Input
                          name="email"
                          type="email"
                          placeholder="E-mail"
                          className="form-control"
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className="mb-4">
                        <Label for="senha">Senha</Label>
                        <Input
                          name="senha"
                          type="password"
                          className="form-control"
                          placeholder="Senha"
                          onChange={onChange}
                          required
                        />
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Lembrar-me
                        </label>
                      </div> */}

                      <div className="mt-3 d-grid">
                        <Button
                          className="btn btn-primary btn-block"
                          size="lg"
                          type="submit"
                          disabled={!validateForm() || desabilitaBotao}
                          onClick={!isLoading ? handleClick : null}
                        >
                          {isLoading ? "Carregando…" : "Login"}
                        </Button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/recuperar-senha" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Esqueceu sua senha?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className=" text-center">
                <p>
                  © {new Date().getFullYear()} Microméros. Feito com{" "}
                  <i className="mdi mdi-heart text-danger" /> e{" "}
                  <i className="mdi mdi-coffee text-brown" /> por{" "}
                  <a
                    href="https://as3engenharia.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={as3logo}
                      alt="AS3 Engenharia"
                      title="AS3 Engenharia"
                      height="30"
                    />
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
