import React from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TabelaEventos from "./TabelaEventos";
import Layout from "../../../components/VerticalLayout";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

function ListaDeEventos() {
  document.title = "Lista de Eventos | Microméros - AS3 Engenharia Elétrica RJ";
  return (
    <Layout>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Monitoramento"
            breadcrumbItem="Lista de eventos"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TabelaEventos />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default ListaDeEventos;
