import React from "react";
import Layout from "../../../components/VerticalLayout";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import TabelaGruposDeAtivos from "./TabelaGruposDeAtivos";

document.title = "Grupos de ativos | Microméros - AS3 Engenharia Elétrica RJ";
function GruposDeAtivos() {
  return (
    <Layout>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Gerenciamento"
            breadcrumbItem="Grupos de ativos"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TabelaGruposDeAtivos />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default GruposDeAtivos;
