import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import './rightbar.scss'

const RightSidebar = (props) => {
  function onCloseRightBar() {
    const { onClose } = props;
    if (onClose) {
      onClose();
    }
  }
  return (
    <React.Fragment>
      <Box
          sx={{ width: 317 }}
          role="presentation"
          onKeyDown={onCloseRightBar}
        >
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={onCloseRightBar}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Configurações</h5>
            </div>
          </div>
          <hr className="my-0" />
        </Box>
    </React.Fragment>
  );
};

export default RightSidebar;
