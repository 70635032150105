import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

function GraficoVisaoGeralAtivos(props) {
  const { saudavel, critico, alerta } = props;

  const series = [saudavel, alerta, critico];

  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -5,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ["Saudáveis", "Alertas", "Críticos"],
    colors: ["#34c38f", "#f1b44c", "#f46a6a"],
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="380"
      />
    </React.Fragment>
  );
}

GraficoVisaoGeralAtivos.propTypes = {
  planta: PropTypes.any,
  grupo: PropTypes.any,
  tag: PropTypes.any,
};

export default GraficoVisaoGeralAtivos;
