import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  Input,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import api from "./../../../services/api";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { Stack } from "react-bootstrap";

const CriarGatilhoModal = (props) => {
  toast.configure();
  const { isOpen, toggle, ativoid } = props;

  //setando variáveis para os dados do formulário
  const [nome, setNome] = useState(null);
  const [sensor, setSensor] = useState(null);
  const [medida, setMedida] = useState(null);
  const [alerta, setAlerta] = useState(null);
  const [alarme, setAlarme] = useState(null);
  const [erroDadosIncorretos, setErroDadosIncorretos] = useState(null);
  const [erroNomeRepetido, setErroNomeRepetido] = useState(null);
  const [erroMesmaMedida, setErroMesmaMedida] = useState(null);
  const [notificaEmail, setNotificaEmail] = useState(false);
  const [notificaWhatsApp, setNotificaWhatsApp] = useState(false);
  const [notificaIBM, setNotificaIBM] = useState(false);
  const [plantaId, setPlantaId] = useState(false);
  const [apiIbm, setApiIbm] = useState(false);
  const [apiWhatsapp, setApiWhatsapp] = useState(false);
  const [desabilitaBotao, setDesabilitaBotao] = useState(false);

  function handleNotificaEmail() {
    setNotificaEmail(!notificaEmail);
  }

  function handleNotificaWhatsApp() {
    setNotificaWhatsApp(!notificaWhatsApp);
  }

  function handleNotificaIBM() {
    setNotificaIBM(!notificaIBM);
  }

  //Listagem de Sensores
  const opcoesSensores = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/ativos/${ativoid}/sensores`, {
        headers: { Authorization: token },
      });
      const res = response.data.sensores.map((sensor) => ({
        value: sensor.sensor_id,
        label: sensor.sensor_nome,
      }));
      return res;
    } catch (err) {}
  };

  //Listagem de medidas
  const opcoesMedidas = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/medidas/lista-de-medidas`, {
        headers: { Authorization: token },
      });
      const res = response.data.medidas.map((medida) => ({
        value: medida.medida_nome,
        label: medida.medida_nome,
      }));
      return res;
    } catch (err) {}
  };

  function onChangeNomeGatilho(event) {
    setNome(event.target.value);
    setErroNomeRepetido(null);
  }
  function handleSensor(sensor) {
    setSensor(sensor.value);
    setErroMesmaMedida(null);
    handlePlantaId(sensor.value);
  }

  function handleMedida(medida) {
    setMedida(medida.value);
    setErroMesmaMedida(null);
  }

  function handlePlantaId(sensorid) {
    const token = localStorage.getItem("token");
    api
      .get(`/plantas/${sensorid}/sensor`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setPlantaId(response.data.planta_id);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if (plantaId) {
      const token = localStorage.getItem("token");
      api
        .get(`/plantas/${plantaId}/api`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setApiIbm(response.data.ibm);
          setApiWhatsapp(response.data.whatsapp);
        })
        .catch((error) => {});
    }
  }, [plantaId]);

  // handleValidSubmit para enviar o formulário via requisição post para micromeros-back
  function onSubmit(event) {
    setDesabilitaBotao(true);
    event.preventDefault();
    const token = localStorage.getItem("token");

    api
      .post(
        `/ativos/${ativoid}/gatilhos`,
        {
          nome,
          sensor,
          medida,
          alerta,
          alarme,
          notificaEmail,
          notificaWhatsApp,
          notificaIBM,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro_mesma_medida) {
          setErroMesmaMedida(error.response.data.mensagem_erro_mesma_medida);
        }
        if (error.response.data.mensagem_erro_nome_repetido) {
          setErroNomeRepetido(error.response.data.mensagem_erro_nome_repetido);
        }
        setDesabilitaBotao(false);
      });
  }

  return (
    <Modal
      id="modal-log-de-trabalho"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
    >
      <Container fluid={true}>
        <div className="modal-header">
          <h5 className="modal-title">Criar gatilho</h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form className="form-horizontal" onSubmit={onSubmit}>
          <ModalBody>
            <div className="mb-3">
              <FormGroup>
                <Label className="form-label">Nome</Label>
                <Input
                  name="nome"
                  type="text"
                  placeholder="Insira o nome do gatilho"
                  className="form-control"
                  onChange={onChangeNomeGatilho}
                  required
                />
                {erroNomeRepetido ? (
                  <>
                    <span className={"text-danger"}>{erroNomeRepetido}</span>
                  </>
                ) : null}
              </FormGroup>
            </div>
            <div className="mb-3">
              <FormGroup className="mb-4">
                <Label className="form-label">Nome do sensor</Label>
                <div className="col-md-12">
                  <AsyncSelect
                    className="text-dark"
                    cacheOptions
                    defaultOptions
                    name="sensor"
                    onChange={handleSensor}
                    loadOptions={opcoesSensores}
                    placeholder="Selecione o sensor"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </FormGroup>
            </div>
            <div className="mb-3">
              <FormGroup className="mb-4">
                <Label className=" text-dark">Nome da medida</Label>
                <div className="col-md-12">
                  <AsyncSelect
                    className="text-dark"
                    cacheOptions
                    defaultOptions
                    name="medida"
                    onChange={handleMedida}
                    loadOptions={opcoesMedidas}
                    placeholder="Selecione a medida"
                    classNamePrefix="select2-selection"
                  />
                  {erroMesmaMedida ? (
                    <>
                      <span className={"text-danger"}>{erroMesmaMedida}</span>
                    </>
                  ) : null}
                </div>
              </FormGroup>
            </div>

            <Row>
              <Col md="6" lg="6">
                <FormGroup>
                  <Label className=" text-dark">Alerta</Label>
                  <Input
                    type="number"
                    className="form-control"
                    onChange={(event) => setAlerta(event.target.value)}
                    placeholder="Valor de Alerta"
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="6">
                <FormGroup>
                  <Label className=" text-dark">Alarme</Label>
                  <Input
                    type="number"
                    className="form-control"
                    onChange={(event) => setAlarme(event.target.value)}
                    placeholder="Valor de Alarme"
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label className=" text-dark">Notificações</Label>

              <Stack direction="horizontal" gap={5}>
                <Col className="ms-1">
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      checked={notificaEmail}
                      id="notificacaoEmail"
                      onChange={handleNotificaEmail}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="notificacaoEmail"
                    >
                      E-mail
                    </Label>
                  </div>
                </Col>
                {apiWhatsapp ? (
                  <Col>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={notificaWhatsApp}
                        id="notificacaoWhatsapp"
                        onChange={handleNotificaWhatsApp}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="notificacaoWhatsapp"
                      >
                        WhatsApp
                      </Label>
                    </div>
                  </Col>
                ) : null}
                {apiIbm ? (
                  <Col>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={notificaIBM}
                        id="notificacaoIBM"
                        onChange={handleNotificaIBM}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="notificacaoIBM"
                      >
                        IBM Maximo
                      </Label>
                    </div>
                  </Col>
                ) : null}
              </Stack>
            </FormGroup>
          </ModalBody>
          <Row>
            <Col xs={12}>
              <div className="modal-footer">
                <Button type="button" color="link" onClick={toggle}>
                  Cancelar
                </Button>
                <button type="submit" className="btn btn-primary btn-block">
                  Salvar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
};

CriarGatilhoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  ativoid: PropTypes.any,
};

export default CriarGatilhoModal;
