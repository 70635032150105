import React from "react";
import Layout from "../../../components/VerticalLayout";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import TabelaGruposDeUsuarios from "./TabelaGruposDeUsuarios";

document.title = "Grupos de usuários | Microméros - AS3 Engenharia Elétrica RJ";
function GrupoDeUsuarios() {
  return (
    <Layout>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Gerenciamento"
            breadcrumbItem="Grupos de usuários"
          />
          <Card>
            <CardBody>
              <TabelaGruposDeUsuarios />
            </CardBody>
          </Card>
        </Container>
      </div>
    </Layout>
  );
}

export default GrupoDeUsuarios;
