import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

function AssetHealth(props) {
  const { healthy, critical, alerts } = props;

  const series = [healthy, critical, alerts];
  const options = {
    stroke: {
      width: 0,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -5,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ["Saudável", "Crítico", "Alerta"],
    colors: ["#34C38F", "#f46a6a", "#f1b44c"],
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="360"
      />
    </React.Fragment>
  );
}

AssetHealth.propTypes = {
  healthy: PropTypes.any,
  critical: PropTypes.any,
  alerts: PropTypes.any,
};

export default AssetHealth;
