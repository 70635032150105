import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from "reactstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";

toast.configure();

const DesvincularGrupoUsuariosModal = (props) => {
  const { isOpen, toggle, grupousuarioid } = props;
  let params = useParams();

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");
    api
      .delete(
        `/gerenciamento/grupos-de-ativos/${params.grupoAtivosId}/grupos-de-usuarios/${grupousuarioid}`,
        { headers: { Authorization: token } }
      )
      .then((response) => {
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          toast.error(error.response.data.mensagem_erro, {
            autoClose: 3000,
          });
        }
      });
  }

  return (
    <Modal
      id="desvincular-grupo-usuario"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
      grupousuarioid={grupousuarioid}
    >
      <Container fluid={true}>
        <ModalHeader>Desvincular grupo de usuários</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <p>
              Você tem certeza que deseja desvincular o grupo de usuários
              selecionado?
            </p>
          </ModalBody>
          <Row>
            <Col xs={12}>
              <div className="modal-footer">
                <Button color="link" type="button" onClick={toggle}>
                  Cancelar
                </Button>
                <button type="submit" className="btn btn-primary btn-block">
                  Excluir
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
};

DesvincularGrupoUsuariosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  grupousuarioid: PropTypes.any,
};

export default DesvincularGrupoUsuariosModal;
