import React, { useState } from "react";
import PropTypes from "prop-types";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

toast.configure();

function EditarNomeGrupoUsuariosModal(props) {
  const { isOpen, toggle, grupousuariosid, setNomeGrupo } = props;

  const [novoGrupoNome, setNovoGrupoNome] = useState(null);
  const [mensagemFalha, setMensagemFalha] = useState(null);

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");

    api
      .put(
        `/gerenciamento/grupos-de-usuarios/${grupousuariosid}/alterar-nome`,
        {
          novoGrupoNome,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setNomeGrupo(response.data.novo_nome);
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 1000,
        });
      })
      .catch((error) => {
        setMensagemFalha(error.response.data.mensagem_falha);
      });
  }
  return (
    <>
      <Modal
        id="modal-editar-grupo-usuarios"
        isOpen={isOpen}
        autoFocus={true}
        className="modal-dialog-centered"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader>Editar nome do Grupo de usuários</ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <div className=" col-md-12 mb-3">
                <Label className=" text-dark">Novo nome</Label>
                <Input
                  name="novoGrupoNome"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setNovoGrupoNome(event.target.value);
                    setMensagemFalha(null);
                  }}
                />
              </div>
              {mensagemFalha ? (
                <span className="text-danger">{mensagemFalha}</span>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="link" onClick={toggle}>
                Cancelar
              </Button>
              <Button color="primary" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </form>
        </div>
      </Modal>
    </>
  );
}

EditarNomeGrupoUsuariosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  grupousuariosid: PropTypes.any,
  setNomeGrupo: PropTypes.func,
};

export default EditarNomeGrupoUsuariosModal;
