import PropTypes from "prop-types";
import React, { useEffect } from "react";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const Logout = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    api
      .get("/logout", { headers: { Authorization: token } })
      .then((response) => {
        toast.success(response.data.message, { autoClose: 2000 });
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro " + err);
      });
    localStorage.clear();
    navigate("/login");
  });

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
