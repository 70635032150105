import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Row,
} from "reactstrap";
import { FiAlertTriangle, FiCheckCircle } from "react-icons/fi";
import Layout from "../../components/VerticalLayout";
import AssetHealth from "./charts/AssetHealth";
import api from "../../services/api";
import moment from "moment";

//Utilidades
import { map } from "lodash";

function Dashboard() {
  const [saudaveis, setSaudaveis] = useState(0);
  const [alertas, setAlertas] = useState(0);
  const [criticos, setCriticos] = useState(0);
  const [eventosAbertos, setEventosAbertos] = useState(0);
  const [eventosFechados, setEventosFechados] = useState(0);
  const [ultimosEventosAbertos, setUltimosEventosAbertos] = useState([]);
  const [total_operacao, setTotalOperacao] = useState(0);
  const [porcentagem_operacao, setPorcentagemOperacao] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");
    api
      .get("/ativos/saude-geral", {
        headers: { Authorization: token },
      })
      .then((response) => {
        setSaudaveis(response.data.saudaveis);
        setCriticos(response.data.criticos);
        setAlertas(response.data.alertas);
      })
      .catch((err) => {});

    api
      .get("/eventos/ultimos-eventos", {
        headers: { Authorization: token },
      })
      .then((response) => {
        setEventosAbertos(response.data.eventosAbertos);
        setEventosFechados(response.data.eventosFechados);
        setUltimosEventosAbertos(response.data.ultimosEventosAbertos);
      })
      .catch((err) => {});

    api
      .get("/medicoes/operacao_potencia_geral", {
        headers: { Authorization: token },
      })
      .then((response) => {
        setTotalOperacao(response.data.total_horas_operacao_mes_corrente);
        setPorcentagemOperacao(response.data.diferenca_porcentagem_operacao);
      })
      .catch((err) => {});
  }, []);

  function formataData(data) {
    let dataISO = moment.utc(data).format();
    let dataConvertida = new Date(dataISO).toLocaleString("pt-BR", {
      timezone: "America/Sao_Paulo",
    });
    return dataConvertida;
  }

  document.title = "Dashboard | Microméros - AS3 Engenharia Elétrica RJ";

  return (
    <Layout>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

          <Row className="p-0">
            <Col lg="6" xl="6">
              <Row className="p-1 pb-0">
                <Card className="mb-2">
                  <CardBody>
                    <CardTitle>Saúde dos Ativos</CardTitle>
                    <Col lg="12" xl="12">
                      {saudaveis === 0 && criticos === 0 && alertas === 0 ? (
                        <>
                          <Row>
                            <Col lg="12">
                              <h5 className="text-center m-5">
                                <span className="badge rounded-pill bg-primary">
                                  i
                                </span>{" "}
                                Não há dados para exibir. <br />
                                Entre em contato com o administrador para
                                revisar suas configurações de acesso.
                              </h5>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <AssetHealth
                          healthy={saudaveis}
                          critical={criticos}
                          alerts={alertas}
                        />
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Row>

              {/*Card operação */}
              {/*TODO: ENVIAR DADOS REAIS DO BACKEND E FAZER CONDIÇÃO */}
              <Row className=" p-1 pt-0">
                <Card>
                  <CardBody>
                    <CardTitle>Operação</CardTitle>
                    {total_operacao === null &&
                    porcentagem_operacao === null ? (
                      <>
                        <Row>
                          <Col lg="12">
                            <h5 className="text-center m-5">
                              <span className="badge rounded-pill bg-primary">
                                i
                              </span>{" "}
                              Não há dados para exibir. <br />
                              Entre em contato com o administrador para revisar
                              suas configurações de acesso.
                            </h5>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <p>Tempo de uso:</p>
                        <h2 className="font-weight-semibold text-center">
                          <span>{total_operacao}h</span>
                        </h2>
                        <p className=" text-center font-size-14">
                          <span className=" badge me-1 bg-primary">
                            {porcentagem_operacao > 0 ? (
                              <>
                                <span>+{porcentagem_operacao}%</span>
                              </>
                            ) : (
                              <>
                                <span>{porcentagem_operacao}%</span>
                              </>
                            )}
                          </span>
                          em relação ao mês anterior
                        </p>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Row>
            </Col>

            <Col lg="6" xl="6" className="p-1">
              <Card style={{ height: "95%" }}>
                <CardBody>
                  <CardTitle>Últimos Eventos</CardTitle>

                  <Row className="mt-3">
                    <Col
                      lg="6"
                      xl="6"
                      sm="6"
                      className="text-center font-size-14"
                    >
                      <p>
                        <FiAlertTriangle
                          className="warning-icon "
                          color="#EBCB22"
                        />{" "}
                        Abertos: {eventosAbertos}{" "}
                      </p>
                    </Col>

                    <Col
                      lg="6"
                      xl="6"
                      sm="6"
                      className="text-center font-size-14"
                    >
                      <p>
                        <FiCheckCircle className="check-icon" color="#2EB434" />{" "}
                        Finalizados: {eventosFechados}
                      </p>
                    </Col>
                  </Row>

                  <table className="table table-hover table-responsive mb-0 ">
                    <tbody>
                      {map(ultimosEventosAbertos, (evento, key) => (
                        <tr key={evento.evento_id}>
                          <td>
                            <Row>
                              <Link to={"/evento/" + evento.evento_id}>
                                <Media>
                                  <Col
                                    lg="1"
                                    sm="1"
                                    md="1"
                                    className="align-self-center "
                                  >
                                    {evento.evento_severidade === "Alerta" ? (
                                      <FiAlertTriangle className="warning-icon font-size-24 text-warning" />
                                    ) : (
                                      <FiAlertTriangle className="warning-icon font-size-24 text-danger" />
                                    )}
                                  </Col>
                                  <Col md="11" sm="11" lg="11" className="ms-2">
                                    <Row>
                                      <span className="text-truncate font-size-14 text-black ">
                                        {evento.gatilho_nome}
                                      </span>
                                    </Row>
                                    <Row>
                                      <span className="text-truncate font-size-14 text-black ">
                                        {evento.medida_nome &&
                                        evento.medicao_valor ? (
                                          <>
                                            {evento.medida_nome + " "} :
                                            {" " +
                                              evento.medicao_valor +
                                              evento.medida_unidade}
                                          </>
                                        ) : null}
                                      </span>
                                    </Row>
                                    <Row>
                                      <span className="text-truncate font-size-14 text-black">
                                        {evento.ativo_nome} -{" "}
                                        {formataData(evento.data_deteccao)}
                                      </span>
                                    </Row>
                                  </Col>
                                </Media>
                              </Link>
                            </Row>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default Dashboard;
