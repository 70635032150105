import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  Input,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from "reactstrap";
import api from "../../../services/api";
import Select from "react-select";
import { Stack } from "react-bootstrap";
import { toast } from "react-toastify";

const EditarGatilhoModal = (props) => {
  const { isOpen, toggle, gatilhoid, alerta, alarme, setAlerta, setAlarme } =
    props;

  //setando variável state para os dados do formulário
  const [sensor, setSensor] = useState("");
  const [medida, setMedida] = useState("");

  const [notificaEmail, setNotificaEmail] = useState(false);
  const [notificaWhatsApp, setNotificaWhatsApp] = useState(false);
  const [notificaIBM, setNotificaIBM] = useState(false);
  const [apiIbm, setApiIbm] = useState(false);
  const [apiWhatsapp, setApiWhatsapp] = useState(false);

  function handleNotificaEmail() {
    setNotificaEmail(!notificaEmail);
  }

  function handleNotificaWhatsApp() {
    setNotificaWhatsApp(!notificaWhatsApp);
  }

  function handleNotificaIBM() {
    setNotificaIBM(!notificaIBM);
  }

  //Modal editar Gatilho

  useEffect(() => {
    if (isOpen === true) {
      const token = localStorage.getItem("token");

      api
        .get(`/gerenciamento/gatilhos/${gatilhoid}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setSensor(response.data.gatilho.sensor_nome);
          setMedida(response.data.gatilho.medida_nome);
          setNotificaIBM(
            Boolean(response.data.gatilho.ativa_notificacao_ibm_maximo)
          );
          setNotificaEmail(
            Boolean(response.data.gatilho.ativa_notificacao_email)
          );
          setNotificaWhatsApp(
            Boolean(response.data.gatilho.ativa_notificacao_whatsapp)
          );
          handleAPI(response.data.gatilho.planta_id);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  function handleAPI(plantaId) {
    const token = localStorage.getItem("token");

    api
      .get(`/plantas/${plantaId}/api`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setApiIbm(response.data.ibm);
        setApiWhatsapp(response.data.whatsapp);
      })
      .catch((error) => {
        console.error("Ops! ocorreu um erro: " + error);
      });
  }

  // handleValidSubmit para enviar o formulário via requisição post para micromeros-back
  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");

    api
      .put(
        `gerenciamento/gatilhos/${props.gatilhoid}`,
        {
          alerta,
          alarme,
          notificaEmail,
          notificaWhatsApp,
          notificaIBM,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error("Ops! Ocorreu um erro: " + err);
      });
  }

  const sensor_select = { value: sensor, label: sensor };
  const medida_select = { value: medida, label: medida };
  return (
    <Modal
      id="modal-log-de-trabalho"
      isOpen={isOpen}
      role="dialog"
      // size="lg"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
      gatilhoid={gatilhoid}
    >
      <Container fluid={true}>
        <ModalHeader>Editar Gatilho</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <div className="mb-3">
              <FormGroup>
                <Label className=" text-dark">Sensor</Label>
                <div>
                  <Select
                    className="text-dark"
                    cacheOptions
                    defaultOptions
                    name="sensor"
                    placeholder=" "
                    value={sensor_select}
                    isDisabled
                  />
                </div>
              </FormGroup>
            </div>
            <div className="mb-3">
              <FormGroup>
                <Label className=" text-dark">Medida</Label>
                <div>
                  <Select
                    className="text-dark"
                    cacheOptions
                    defaultOptions
                    name="medida"
                    placeholder=" "
                    value={medida_select}
                    isDisabled
                  />
                </div>
              </FormGroup>
            </div>

            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <FormGroup>
                    <Label className=" text-dark">Alerta</Label>
                    <Input
                      type="text"
                      className="form-control"
                      onChange={(event) => setAlerta(event.target.value)}
                      defaultValue={alerta}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <FormGroup>
                    <Label className=" text-dark">Alarme</Label>
                    <Input
                      type="text"
                      className="form-control"
                      onChange={(event) => setAlarme(event.target.value)}
                      defaultValue={alarme}
                    />
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <FormGroup>
              <Label className=" text-dark">Notificações</Label>

              <Stack direction="horizontal" gap={5}>
                <Col className="ms-1">
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      checked={notificaEmail}
                      id="notificacaoEmail"
                      onChange={handleNotificaEmail}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="notificacaoEmail"
                    >
                      E-mail
                    </Label>
                  </div>
                </Col>
                {apiWhatsapp ? (
                  <Col>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={notificaWhatsApp}
                        id="notificacaoWhatsapp"
                        onChange={handleNotificaWhatsApp}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="notificacaoWhatsapp"
                      >
                        WhatsApp
                      </Label>
                    </div>
                  </Col>
                ) : null}
                {apiIbm ? (
                  <Col>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={notificaIBM}
                        id="notificacaoIBM"
                        onChange={handleNotificaIBM}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="notificacaoIBM"
                      >
                        IBM Maximo
                      </Label>
                    </div>
                  </Col>
                ) : null}
              </Stack>
            </FormGroup>
          </ModalBody>
          <Row>
            <Col xs={12}>
              <div className="modal-footer">
                <Button type="button" color="link" onClick={toggle}>
                  Cancelar
                </Button>
                <button type="submit" className="btn btn-primary btn-block">
                  Salvar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
};

EditarGatilhoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  gatilhoid: PropTypes.any,
  alerta: PropTypes.any,
  alarme: PropTypes.any,
  setAlerta: PropTypes.func,
  setAlarme: PropTypes.func,
};

export default EditarGatilhoModal;
