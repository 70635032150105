import { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";

function NavInformacoesAtivo(props) {
  const [activeTab, setactiveTab] = useState("1");

  function NavItems(activeTabId, title) {
    return (
      <NavItem>
        <NavLink
          className={classnames({
            active: activeTab === activeTabId,
          })}
          onClick={() => {
            setactiveTab(activeTabId);
          }}
        >
          <span className="d-none d-sm-block font-size-12">{title}</span>
        </NavLink>
      </NavItem>
    );
  }

  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardBody>
            <Row>
              <Col xl="12">
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {NavItems("1", "Visão Geral")}
                  {NavItems("2", "Informações do ativo")}
                  {NavItems("3", "Informações do sensor")}
                  {NavItems("4", "Gatilhos")}
                </Nav>
              </Col>
            </Row>
            <Row>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Tab1 ativo={props.ativo} />
                </TabPane>
                <TabPane tabId="2">
                  <Tab2 ativo={props.ativo} />
                </TabPane>
                <TabPane tabId="3">
                  <Tab3 ativo={props.ativo} />
                </TabPane>
                <TabPane tabId="4">
                  <Tab4 />
                </TabPane>
              </TabContent>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
NavInformacoesAtivo.propTypes = {
  ativo: PropTypes.object,
};
export default NavInformacoesAtivo;
