import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { Row } from "react-bootstrap";

toast.configure();

const InserirLogDeTrabalhoModal = (props) => {
  const { isOpen, toggle, eventoid } = props;

  //setando variável state para os dados do formulário
  const [comentario, setComentario] = useState({ comentario: "" });

  //setando função que armazena valores em comentario
  function onChange(event) {
    const { value, name } = event.target;

    setComentario({
      ...comentario,
      [name]: value,
    });
  }

  // handleValidSubmit para enviar o formulário via requisição post para micromeros-back
  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");

    api
      .post(`/eventos/${eventoid}/novo-log-de-evento`, comentario, {
        headers: { Authorization: token },
      })
      .then((response) => {
        toast.success("Log de trabalho criado com sucesso!", {
          autoClose: 3000,
        });
        toggle();
      })
      .catch((err) => {
        toast.error(err, {
          autoClose: 3000,
        });
      });
  }

  return (
    <Modal
      id="modal-log-de-trabalho"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      tabIndex="-1"
      toggle={toggle}
      eventoid={eventoid}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Informar log de trabalho</ModalHeader>
        <form className="form-horizontal" onSubmit={onSubmit}>
          <ModalBody>
            <Row className="mx-0 mt-n1">
              <Input
                name="comentario"
                type="textarea"
                rows="4"
                value={comentario.comentario}
                placeholder="Comente aqui..."
                className="form-control"
                onChange={onChange}
                required
              />
            </Row>
            <Row className="ms-n2 mt-1">
              {comentario.comentario.length < 10 ? (
                <span className="text-danger">Minimo de 10 caracteres</span>
              ) : null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="link" onClick={toggle}>
              Cancelar
            </Button>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={comentario.comentario.length < 10 ? true : false}
            >
              Salvar
            </button>
          </ModalFooter>
        </form>
      </div>
    </Modal>
  );
};

InserirLogDeTrabalhoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  eventoid: PropTypes.any,
};

export default InserirLogDeTrabalhoModal;
