import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from "reactstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";

toast.configure();

const ExcluirGrupoUsuariosModal = (props) => {
  const { isOpen, toggle, grupousuariosid, setGrupos } = props;

  // handleValidSubmit para enviar o formulário via requisição delete para micromeros-back
  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");

    api
      .delete(`/gerenciamento/grupos-de-usuarios/${grupousuariosid}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setGrupos(response.data.grupos_usuarios.data);
        toggle();
        toast.success(response.data.mensagem_exclusao_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro_autorizacao) {
          toast.error(error.response.data.mensagem_erro_autorizacao, {
            autoClose: 3000,
          });
        }
      });
  }

  return (
    <Modal
      id="excluir-grupo-usuarios"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
      grupousuariosid={grupousuariosid}
    >
      <Container fluid={true}>
        <ModalHeader>Excluir Grupo de Usuários</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <p>
              Você tem certeza que deseja excluir o grupo de usuários
              selecionado?
            </p>
          </ModalBody>
          <Row>
            <Col xs={12}>
              <div className="modal-footer">
                <Button color="link" type="button" onClick={toggle}>
                  Cancelar
                </Button>
                <button type="submit" className="btn btn-primary btn-block">
                  Excluir
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
};

ExcluirGrupoUsuariosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  grupousuariosid: PropTypes.any,
  setGrupos: PropTypes.func,
};

export default ExcluirGrupoUsuariosModal;
