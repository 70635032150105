import PropTypes from "prop-types";
import { CardTitle, Col, Media, Row } from "reactstrap";

function Tab3(props) {
  return (
    <>
      <Row lg="12" className="mt-4">
        <Col>
          <CardTitle className="mb-3">Detalhes do sensor</CardTitle>
          <ul className="row p-0">
            <li className="list-inline-item mb-2">
              <Media>
                <strong>ID do Sensor</strong>
                <Media body>
                  <span className="float-end ">
                    {props.ativo.sensor_nome
                      ? props.ativo.sensor_nome
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Número de série</strong>
                <Media body>
                  <span className="float-end ">
                    {props.ativo.sensor_serial_number
                      ? props.ativo.sensor_serial_number
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Tipo</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.sensor_tipo
                      ? props.ativo.sensor_tipo
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Firmware version</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.sensor_versao_firmware
                      ? props.ativo.sensor_versao_firmware
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Hardware revision</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.sensor_versao_hardware
                      ? props.ativo.sensor_versao_hardware
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>MAC</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.sensor_mac
                      ? props.ativo.sensor_mac
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Chip ID</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.sensor_chip_id
                      ? props.ativo.sensor_chip_id
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Fabricante</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.sensor_fabricante
                      ? props.ativo.sensor_fabricante
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Gateway</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.gateway_nome
                      ? props.ativo.gateway_nome
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Gateway MAC</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.gateway_mac
                      ? props.ativo.gateway_mac
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Modelo da Gateway</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.gateway_modelo
                      ? props.ativo.gateway_modelo
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Status da rede da gateway</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.gateway_status
                      ? props.ativo.gateway_status
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>
          </ul>
        </Col>
        <Col>
          <CardTitle className="mb-3">Funcionalidade do sensor</CardTitle>
          <ul className="row p-0">
            <li className="list-inline-item mb-2">
              <Media>
                <strong>Modo de economia de energia da bateria</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.sensor_economia_bateria === 1
                      ? "Habilitado"
                      : "Desabilitado"}
                  </span>
                </Media>
              </Media>
            </li>
            <li className="list-inline-item mb-2">
              <Media>
                <strong>Intervalo de medição</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.intervalo_medicao
                      ? props.ativo.intervalo_medicao
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Sensor para Gateway - valor do RSSI</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.sensor_gateway_rssi
                      ? props.ativo.sensor_gateway_rssi
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
}

Tab3.propTypes = {
  ativo: PropTypes.object,
};

export default Tab3;
