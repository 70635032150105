import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

function GraficoComparativo(props) {
  const series = [
    {
      type: "area",
      name: props.ativoNomeReferencia + " (Referência)",
      data: props.medicoesReferencia,
    },
    {
      type: "area",
      name: props.ativoNomeAtivo2,
      data: props.medicoesAtivo2,
    },
    {
      type: "area",
      name: props.ativoNomeAtivo3,
      data: props.medicoesAtivo3,
    },
    {
      type: "area",
      name: props.ativoNomeAtivo4,
      data: props.medicoesAtivo4,
    },
    {
      type: "area",
      name: props.ativoNomeAtivo5,
      data: props.medicoesAtivo5,
    },
  ];

  const options = {
    chart: { toolbar: { show: true } },
    dataLabels: { enabled: !1 },
    stroke: { curve: "straight", width: 2, dashArray: [0, 0, 0, 0, 0] },
    fill: { type: "solid", opacity: [0, 0, 0, 0, 0] },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      x: {
        formatter: function (val) {
          return new Date(val).toLocaleString();
        },
      },
    },
    yaxis: [{ title: { text: props.medidaReferencia } }],
    colors: ["#f1b44c", "#6452e1", "#8B4513", "#B0E0E6", "#3CB371"],
    legend: {
      showForNullSeries: false,
      position: "top",
      horizontalAlign: "center",
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="391"
        className="apex-charts"
      />
    </>
  );
}

GraficoComparativo.propTypes = {
  medidaReferencia: PropTypes.any,
  ativoNomeReferencia: PropTypes.any,
  medicoesReferencia: PropTypes.any,
  timestampReferencia: PropTypes.any,
  ativoNomeAtivo2: PropTypes.any,
  medicoesAtivo2: PropTypes.any,
  ativoNomeAtivo3: PropTypes.any,
  medicoesAtivo3: PropTypes.any,
  ativoNomeAtivo4: PropTypes.any,
  medicoesAtivo4: PropTypes.any,
  ativoNomeAtivo5: PropTypes.any,
  medicoesAtivo5: PropTypes.any,
};

export default GraficoComparativo;
