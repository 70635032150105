import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumbs = (props) => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
          <div className="page-title-right">
            <Breadcrumb listClassName="m-0">
              <BreadcrumbItem>
                {props.link != null ? (
                  <>
                    <Link to={props.link}>{props.title}</Link>
                  </>
                ) : (
                  <>{props.title}</>
                )}
              </BreadcrumbItem>
              <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
};

export default Breadcrumbs;
