import React, {useEffect} from "react";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";


// TODO: Importar RightSidebar quando estiver pronta

function Layout({children}) {

  

 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          {children}
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Layout;
