import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  PopoverBody,
  Row,
  Table,
  UncontrolledPopover,
} from "reactstrap";
import Stack from "react-bootstrap/Stack";
import DatePicker from "react-datepicker";
import api from "../../../../../../services/api";
import moment from "moment";
import AtivoGraficoMedicoes from "./AtivoGraficoMedicoes";
import { useParams, Link } from "react-router-dom";
import { map } from "lodash";

function Tab1(props) {
  let params = useParams();

  //botao Período
  const [startDate, setStartDate] = useState(
    new Date(moment().subtract(7, "days").format("YYYY-MM-DD"))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [series, setSeries] = useState([]);
  const [timestamp, setTimestamp] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [colors, setColors] = useState([]);
  const [checked, setChecked] = useState([]);

  function toDateString(date, hour) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      hour
    );
  }

  function handleMedidas() {
    const token = localStorage.getItem("token");

    if (startDate) {
      setDataInicial(toDateString(startDate, "00:00:00"));
    } else {
      setDataInicial(null);
    }
    if (endDate) {
      setDataFinal(toDateString(endDate, "23:59:59"));
    } else {
      setDataFinal(null);
    }

    try {
      api
        .post(
          `/ativos/${params.ativoId}/medicoes`,
          { dataInicial, dataFinal, checked },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setSeries(response.data.series);
          setTimestamp(
            response.data.timestamp.map((timestamp) =>
              new Date(timestamp).toLocaleString("pt-BR", { timeZone: "UTC" })
            )
          );
          setYaxis(response.data.yaxis);
          setColors(response.data.colors);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      alert(e.message);
    }
  }

  function onSubmit(event) {
    event.preventDefault();
    handleMedidas();
  }

  useEffect(() => {
    if (checked.length > 0) {
      handleMedidas();
    }
  }, [checked.length]);

  return (
    <>
      <Row className="m-2 align-items-center">
        <Col md={6} sm={12} xl={6} className="mt-2 font-size-14">
          <Label>Última medição: {props.ativo.ultima_medicao}</Label>
        </Col>
        <Col md={6} sm={12} xl={6}>
          <Form onSubmit={onSubmit}>
            <Stack direction="horizontal" gap={1}>
              <Label className=" text-dark mt-2 font-size-14">Período: </Label>

              <DatePicker
                name="startDate"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setDataInicial(toDateString(date, "00:00:00"));
                }}
                maxDate={endDate ? endDate : new Date()}
                autoComplete="off"
              />

              <DatePicker
                name="endDate"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setDataFinal(toDateString(date, "23:59:59"));
                }}
                minDate={startDate}
                maxDate={new Date()}
                autoComplete="off"
              />

              <Button color="primary" type="submit">
                Filtrar
              </Button>
            </Stack>
          </Form>
        </Col>
      </Row>
      <AtivoGraficoMedicoes
        series={series}
        timestamp={timestamp}
        yaxis={yaxis}
        colors={colors}
        escolheMedidas={checked}
      />
      <hr />
      <Table>
        <thead>
          <tr>
            <th>Parâmetros</th>
            <th>Últimas medições</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {map(props.ativo.medidas, (medida) => (
            <tr key={medida.medida_id}>
              <td>
                {medida.medida_nome}
                {medida.descricao ? (
                  <>
                    <Link
                      to="#"
                      id={"descricao-medida" + medida.medida_id}
                      color="primary"
                      className="badge rounded-pill bg-primary ms-1"
                    >
                      i
                    </Link>

                    <UncontrolledPopover
                      placement="right"
                      target={"descricao-medida" + medida.medida_id}
                      trigger="focus"
                    >
                      <PopoverBody>{medida.descricao}</PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
              </td>
              <td>
                {medida.ultima_medicao_valor + " " + medida.medida_unidade}
              </td>
              <td>
                <div className="form-check form-switch mb-3">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id={"escolhe-medidas-" + medida.medida_id}
                    onChange={(event) => {
                      var updatedList = [...checked];
                      if (event.target.checked) {
                        updatedList = [...checked, medida.medida_id];
                      } else {
                        updatedList.splice(
                          checked.indexOf(medida.medida_id),
                          1
                        );
                      }
                      setChecked(updatedList);
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
Tab1.propTypes = {
  ativo: PropTypes.object,
};
export default Tab1;
