import React, { useState } from "react";

import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";

// Import menuDropdown

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";
import RightSidebar from "../CommonForBoth/RightSidebar";

const Header = (props) => {
  
  const [openRightSidebar, setopenRightSidebar] = useState(false);

  function handleOnClick() {
    setopenRightSidebar(!openRightSidebar);
  }

  //Essa função está alterando classes do <body> em index.html
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            
          </div>
          <div className="d-flex">
           

            
            <ProfileMenu />

            {/* Botão que abre a RightSidebar */}
            {/* <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
                onClick={handleOnClick}
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
      <Drawer anchor="right" open={openRightSidebar} onClose={handleOnClick}>
        <RightSidebar onClose={handleOnClick} />
      </Drawer>
    </React.Fragment>
  );
};

export default Header;
