import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { Stack } from "react-bootstrap";

const EditarGrupoAtivosModal = (props) => {
  const { isOpen, toggle, grupoativoid, moderator } = props;
  const [planta, setPlanta] = useState(0);
  const [grupoNome, setGrupoNome] = useState(0);
  const [frequenciaNotificacao, setFrequenciaNotificacao] = useState(null);
  const [isDefault, setIsDefault] = useState();
  const [notificaEmail, setNotificaEmail] = useState(false);
  const [notificaWhatsApp, setNotificaWhatsApp] = useState(false);
  const [notificaIBM, setNotificaIBM] = useState(false);
  const [apiIbm, setApiIbm] = useState(false);
  const [apiWhatsapp, setApiWhatsapp] = useState(false);

  function handleNotificaEmail() {
    setNotificaEmail(!notificaEmail);
  }

  function handleNotificaWhatsApp() {
    setNotificaWhatsApp(!notificaWhatsApp);
  }

  function handleNotificaIBM() {
    setNotificaIBM(!notificaIBM);
  }

  function handleFrequenciaNotificacao(frequenciaNotificacao) {
    frequenciaNotificacao === null
      ? setFrequenciaNotificacao(null)
      : setFrequenciaNotificacao(frequenciaNotificacao / 60);
  }

  function limparCampos() {
    setPlanta(0);
    setNotificaIBM(false);
    setNotificaWhatsApp(false);
    setNotificaEmail(false);
    setGrupoNome(0);
    setFrequenciaNotificacao(null);
  }

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");
    api
      .put(
        `/gerenciamento/grupos-de-ativos/${grupoativoid}`,
        {
          grupoNome,
          frequenciaNotificacao,
          notificaEmail,
          notificaWhatsApp,
          notificaIBM,
          moderator,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        limparCampos();
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          toast.error(error.response.data.mensagem_erro, {
            autoClose: 3000,
          });
        }
      });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    api
      .get(`/gerenciamento/grupos-de-ativos/${grupoativoid}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setGrupoNome(response.data.grupo_ativos.grupo_ativos_nome);
        setPlanta(response.data.grupo_ativos.planta);
        handleFrequenciaNotificacao(response.data.grupo_ativos.frequencia);
        setNotificaEmail(Boolean(response.data.grupo_ativos.notificaEmail));
        setNotificaIBM(Boolean(response.data.grupo_ativos.notificaIBM));
        setNotificaWhatsApp(
          Boolean(response.data.grupo_ativos.notificaWhatsApp)
        );
        setIsDefault(response.data.grupo_ativos.is_default);
        handleAPI(response.data.grupo_ativos.planta_id);
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          toast.error(error.response.data.mensagem_erro, {
            autoClose: 3000,
          });
        }
      });
  }, [toggle, grupoativoid]);

  function handleAPI(plantaId) {
    const token = localStorage.getItem("token");

    api
      .get(`/plantas/${plantaId}/api`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setApiIbm(response.data.ibm);
        if (!response.data.ibm) {
          setNotificaIBM(false);
        }
        setApiWhatsapp(response.data.whatsapp);
        if (!response.data.whatsapp) {
          setNotificaWhatsApp(false);
        }
      })
      .catch((error) => {
        console.error("Ops! ocorreu um erro: " + error);
      });
  }

  return (
    <Modal
      id="editar-grupo-ativo"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
      grupoativoid={grupoativoid}
    >
      <Container fluid={true}>
        <div className="modal-content">
          <Form onSubmit={onSubmit}>
            <ModalHeader>Editar Grupo de ativos</ModalHeader>

            <ModalBody>
              <div className=" col-md-12 mb-3">
                <Label className=" text-dark">Nome do grupo</Label>
                <Input
                  disabled={isDefault || moderator}
                  name="nome"
                  type="text"
                  className="form-control"
                  placeholder={grupoNome}
                  onChange={(event) => setGrupoNome(event.target.value)}
                />
              </div>

              <div className="col-md-12 mb-3">
                <Label className=" text-dark">Planta</Label>
                <Select
                  className="text-dark"
                  cacheOptions
                  defaultOptions
                  name="planta"
                  isDisabled={true}
                  placeholder={planta}
                  classNamePrefix="select2-selection"
                />
              </div>
              <div>
                <Label className=" text-dark">Notificações</Label>
              </div>
              <Stack direction="horizontal" gap={5}>
                <Col className="ms-1">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={notificaEmail}
                      id="notificacaoEmail"
                      onChange={handleNotificaEmail}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="notificacaoEmail"
                    >
                      E-mail
                    </label>
                  </div>
                </Col>

                {apiWhatsapp ? (
                  <Col>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={notificaWhatsApp}
                        id="notificacaoWhatsapp"
                        onChange={handleNotificaWhatsApp}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="notificacaoWhatsapp"
                      >
                        WhatsApp
                      </label>
                    </div>
                  </Col>
                ) : null}
                {apiIbm ? (
                  <Col>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={notificaIBM}
                        id="notificacaoIBM"
                        onChange={handleNotificaIBM}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="notificacaoIBM"
                      >
                        IBM Maximo
                      </label>
                    </div>
                  </Col>
                ) : null}
              </Stack>
              {notificaIBM !== false ||
              notificaWhatsApp !== false ||
              notificaEmail !== false ? (
                <>
                  <div className=" col-md-12 mt-3">
                    <Label className=" text-dark">
                      Frequência de notificações (horas)
                    </Label>
                    <Col lg="4" sm="4">
                      <Input
                        name="frequencia_notificacao"
                        type="number"
                        min="1"
                        placeholder={frequenciaNotificacao}
                        className="form-control"
                        onChange={(event) =>
                          setFrequenciaNotificacao(event.target.value)
                        }
                      />
                    </Col>
                  </div>
                </>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="link"
                onClick={() => {
                  limparCampos();
                  toggle();
                }}
              >
                Cancelar
              </Button>
              <Button color="primary" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Container>
    </Modal>
  );
};

EditarGrupoAtivosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  grupoativoid: PropTypes.any,
  moderator: PropTypes.bool,
};

export default EditarGrupoAtivosModal;
