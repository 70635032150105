import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";

// import images
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import as3logo from "../../assets/images/as3-engenharia-menor-2.png";

export default function DesbloquearConta() {
  let params = useParams();
  const [isTokenRefreshValid, setIsTokenRefreshValid] = useState(false);

  const navigate = useNavigate();
  toast.configure();

  useEffect(() => {
    api
      .get(`/desbloquear-conta/${params.refreshToken}`)
      .then((response) => {
        setIsTokenRefreshValid(response.data.isTokenRefreshValid);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      })
      .catch((err) => {
        setIsTokenRefreshValid(err.response.data.isTokenRefreshValid);
        toast.error(err, {
          autoClose: 3000,
        });
      });
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      {isTokenRefreshValid ? (
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Desbloqueio de conta</h5>
                        </div>
                      ) : (
                        <>
                          <div className="text-primary p-5">
                            <h5 className="text-primary">Link inválido</h5>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* Logo do Microméros */}
                  <div className="auth-logo">
                    <div className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    {isTokenRefreshValid ? (
                      <>
                        <div className="text-primary p-4">
                          <span>Sua conta foi desbloqueada! </span>
                          <div>
                            <span>
                              Você será redirecionado para a tela de login em
                              alguns segundos.
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="mt-2 text-center">
                        <p>O link informado é inválido ou está expirado</p>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className=" text-center">
                <p>
                  © {new Date().getFullYear()} Microméros. Feito com{" "}
                  <i className="mdi mdi-heart text-danger" /> e{" "}
                  <i className="mdi mdi-coffee text-brown" /> por{" "}
                  <a
                    href="https://as3engenharia.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={as3logo}
                      alt="AS3 Engenharia"
                      title="AS3 Engenharia"
                      height="30"
                    />
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
