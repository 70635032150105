import { Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

function AtivoGraficoMedicoes(props) {
  const medidas = props.escolheMedidas;
  const options = {
    noData: {
      text: "Não há dados disponíveis",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "Roboto",
      },
    },
    chart: { toolbar: { show: true }, type: "line" },
    dataLabels: { enabled: !1 },
    tooltip: { shared: true },
    stroke: {
      curve: "straight",
      width: 2,
    },
    xaxis: {
      categories: props.timestamp,
    },
    yaxis: props.yaxis,
    colors: props.colors,
  };

  const series = props.series;

  return (
    <Row>
      <Col sm="12">
        {medidas.length > 0 ? (
          <ReactApexChart
            options={options}
            series={series}
            height="391"
            className="apex-charts"
          />
        ) : (
          <div className="text-center mt-5 mb-5">
            {" "}
            <h5>Selecione um parâmetro</h5>
            <p>
              Para adicionar parâmetros, acione o respectivo botão para qualquer
              um dos parâmetros abaixo da área do gráfico.
            </p>
            <p>Você pode comparar vários parâmetros ao mesmo tempo.</p>
          </div>
        )}
      </Col>
    </Row>
  );
}

AtivoGraficoMedicoes.propTypes = {
  series: PropTypes.array,
  timestamp: PropTypes.array,
  yaxis: PropTypes.array,
  colors: PropTypes.array,
  escolheMedidas: PropTypes.array,
};

export default AtivoGraficoMedicoes;
