//react
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//Utilidades
import { map } from "lodash";

//Layout
import Layout from "../../../components/VerticalLayout";
import ReactTooltip from "react-tooltip";

//Componentes Bootstrap / Reactstrap
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import classnames from "classnames";
//Componentes Select
import AsyncSelect from "react-select/async";

//Paginação
import Pagination from "react-js-pagination";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//API
import api from "../../../services/api";

//Gráficos
import GraficoVisaoGeralAtivos from "./Graficos/GraficosAtivos/GraficoVisaoGeralAtivos";
import GraficoTotalEventosAtivos from "./Graficos/GraficosAtivos/GraficoTotalEventosAtivos";

//Imagens
import FotoAtivo from "../../../assets/images/foto-generica-ativo.jpg";

function Ativos() {
  //Gráfico visão geral ativos
  const [saudavel, setSaudavel] = useState(0);
  const [critico, setCritico] = useState(0);
  const [alerta, setAlerta] = useState(0);
  //Gráfico Total de Eventos
  const [manutencao, setManutencao] = useState(0);
  const [alertas, setAlertas] = useState(0);
  const [alarmes, setAlarmes] = useState(0);
  //paginação de ativos
  const [activePage, setActivePage] = useState(1);
  const [ativosTotal, setAtivosTotal] = useState(0);
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }
  //filtros
  const [fotoAtivo] = useState(FotoAtivo);
  const [planta, setPlanta] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [tag, setTag] = useState([]);
  const [ativos, setAtivos] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [abrefiltro, setAbreFiltro] = useState(false);

  //Limpar filtros
  function limpaFiltros() {
    setPlanta([]);
    setGrupo([]);
    setTag([]);
  }
  //Collapse Filtro
  const toggleFiltro = () => {
    setAbreFiltro(!abrefiltro);
  };
  //Selects
  //Select -> Listagem de Plantas
  const opcoesPlanta = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/plantas/lista-de-plantas`, {
        headers: { Authorization: token },
      });
      const res = response.data.plantas.map((planta) => ({
        value: planta.planta_id,
        label: planta.planta_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Select -> Listagem de Grupos
  const opcoesGrupo = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/grupos-de-ativos`, {
        headers: { Authorization: token },
      });
      const res = response.data.grupos_ativos.map((grupo) => ({
        value: grupo.grupo_id,
        label: grupo.grupo_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Select -> Listagem de Tags
  const opcoesTag = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/tags/lista-de-tags`, {
        headers: { Authorization: token },
      });
      const res = response.data.tags.map((tag) => ({
        value: tag.tag_id,
        label: tag.tag_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  function handlePlanta(planta) {
    setPlanta(planta);
  }

  function handleGrupo(grupo) {
    setGrupo(grupo);
  }

  function handleTag(tag) {
    setTag(tag);
  }

  useEffect(() => {
    //regra de negócio 01
    if (planta.length > 0 || grupo.length > 0 || tag.length > 0) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    const token = localStorage.getItem("token");

    try {
      //listagem dos cards de ativos
      api
        .post(
          `/ativos?page=${activePage}`,
          {
            planta,
            grupo, //ID
            tag,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setAtivos(response.data.ativos.data);
          setAtivosTotal(response.data.ativos.total);
        })
        .catch((err) => {
          console.error(err);
        });
      //grafico visão geral dos ativos

      api
        .post(
          `/ativos/visao-geral-ativos`,
          {
            planta,
            grupo,
            tag,
          },
          { headers: { Authorization: token } }
        )
        .then((response) => {
          setSaudavel(response.data.saudaveis);
          setCritico(response.data.criticos);
          setAlerta(response.data.alertas);
        })
        .catch((err) => {
          console.error("Ops! ocorreu um erro: " + err);
        });

      //grafico total de eventos
      api
        .post(
          `/eventos/total-eventos`,
          {
            planta,
            grupo,
            tag,
          },
          { headers: { Authorization: token } }
        )
        .then((response) => {
          setManutencao(response.data.manutencao);
          setAlertas(response.data.alertas);
          setAlarmes(response.data.alarmes);
        })
        .catch((err) => {
          console.error("Ops! ocorreu um erro: " + err);
        });
    } catch (e) {
      alert(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Filtrar
  function handleSubmit(event) {
    event.preventDefault();

    //regra de negócio 01
    if (planta.length > 0 || grupo.length > 0 || tag.length > 0) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    const token = localStorage.getItem("token");

    try {
      //listagem dos cards de ativos
      api
        .post(
          `/ativos?page=${activePage}`,
          {
            planta,
            grupo, //ID
            tag,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setAtivos(response.data.ativos.data);
          setAtivosTotal(response.data.ativos.total);
        })
        .catch((err) => {
          console.error(err);
        });
      //grafico visão geral dos ativos

      api
        .post(
          `/ativos/visao-geral-ativos`,
          {
            planta,
            grupo,
            tag,
          },
          { headers: { Authorization: token } }
        )
        .then((response) => {
          setSaudavel(response.data.saudaveis);
          setCritico(response.data.criticos);
          setAlerta(response.data.alertas);
        })
        .catch((err) => {
          console.error("Ops! ocorreu um erro: " + err);
        });

      //grafico total de eventos
      api
        .post(
          `/eventos/total-eventos`,
          {
            planta,
            grupo,
            tag,
          },
          { headers: { Authorization: token } }
        )
        .then((response) => {
          setManutencao(response.data.manutencao);
          setAlertas(response.data.alertas);
          setAlarmes(response.data.alarmes);
        })
        .catch((err) => {
          console.error("Ops! ocorreu um erro: " + err);
        });
    } catch (e) {
      alert(e.message);
    }
  }

  document.title = "Ativos | Microméros - AS3 Engenharia Elétrica RJ";
  return (
    <Layout>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Monitoramento"
            breadcrumbItem="Monitoramento dos Ativos"
          />

          {/* FILTROS */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFlushOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !abrefiltro,
                            }
                          )}
                          type="button"
                          onClick={toggleFiltro}
                          style={{ cursor: "pointer" }}
                        >
                          <CardTitle className="h4">
                            Filtros{" "}
                            {isFiltered ? (
                              <span className="badge rounded-pill bg-primary">
                                Contém filtros
                              </span>
                            ) : null}
                          </CardTitle>
                        </button>
                      </h2>

                      <Collapse
                        isOpen={abrefiltro}
                        className="accordion-collapse"
                      >
                        <div className="accordion-body">
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              {" "}
                              {/* PRIMEIRA FILEIRA */}
                              {/* PLANTA*/}
                              <Col lg="6">
                                <div className="mb-3 select2-container">
                                  <Label className=" text-dark">Planta</Label>
                                  <AsyncSelect
                                    className="text-dark"
                                    cacheOptions
                                    defaultOptions
                                    name="planta"
                                    onChange={handlePlanta}
                                    loadOptions={opcoesPlanta}
                                    placeholder="Selecione a planta"
                                    classNamePrefix="select2-selection"
                                    value={planta}
                                    isMulti
                                  />
                                </div>
                              </Col>
                              {/* GRUPO */}
                              <Col lg="6">
                                <div className="mb-3 select2-container">
                                  <Label className=" text-dark">
                                    Grupo de ativos
                                  </Label>
                                  <AsyncSelect
                                    className="text-dark"
                                    cacheOptions
                                    defaultOptions
                                    name="grupo"
                                    onChange={handleGrupo}
                                    loadOptions={opcoesGrupo}
                                    placeholder="Selecione o grupo"
                                    classNamePrefix="select2-selection"
                                    value={grupo}
                                    isMulti
                                  />
                                </div>
                              </Col>
                            </Row>

                            {/* SEGUNDA FILEIRA */}
                            <Row>
                              {/* NOME DO ATIVO */}
                              <Col lg="12">
                                <div className="mb-3 select2-container">
                                  <Label className=" text-dark">Tag</Label>
                                  <AsyncSelect
                                    className="text-dark"
                                    cacheOptions
                                    defaultOptions
                                    name="tag"
                                    onChange={handleTag}
                                    loadOptions={opcoesTag}
                                    placeholder="Selecione a tag"
                                    classNamePrefix="select2-selection"
                                    value={tag}
                                    isMulti
                                  />
                                </div>
                              </Col>
                            </Row>

                            {/* TERCEIRA FILEIRA */}
                            <Row>
                              <Col lg="12" className="text-center">
                                <Button
                                  color="link"
                                  type="button"
                                  onClick={limpaFiltros}
                                >
                                  Limpar filtros
                                </Button>

                                <Button color="primary" type="submit">
                                  Filtrar
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <>
            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <Col lg="12">
                      <CardTitle className="mb-4">Visão geral </CardTitle>
                      <GraficoVisaoGeralAtivos
                        saudavel={saudavel}
                        critico={critico}
                        alerta={alerta}
                      />
                    </Col>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6">
                <Card>
                  <CardBody>
                    <Col lg="12">
                      <CardTitle className="mb-4">Total de eventos </CardTitle>
                      <GraficoTotalEventosAtivos
                        manutencao={manutencao}
                        alarmes={alarmes}
                        alertas={alertas}
                      />
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              {map(ativos, (ativo, key) => (
                <Col lg="6" xl="4" sm="12" key={key}>
                  <Link to={"/ativo/" + ativo.ativo_id}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col lg="3" sm="2" className="mt-4">
                            <div className="avatar-md me-4 ">
                              <span className=" rounded-square bg-light">
                                <img
                                  src={fotoAtivo}
                                  alt=""
                                  height="60"
                                  width="80"
                                />
                              </span>
                            </div>
                          </Col>
                          <Col lg="5" sm="8" className="mt-4 ">
                            {" "}
                            <span className="text-truncate font-size-12 text-black">
                              {ativo.ativo_nome}
                            </span>
                            <br />
                            <span className="text-muted">
                              {ativo.grupo_ativos_nome}
                            </span>
                            <br />
                            <Badge className="rounded bg-info bg-soft text-primary ">
                              {ativo.tag_nome}
                            </Badge>
                          </Col>
                          <Col lg="4" sm="2" className="text-end">
                            <span data-tip data-for="tooltip-temperatura">
                              {ativo.temperatura ? (
                                <i
                                  className={
                                    " bx bxs-thermometer font-size-18 text-" +
                                    ativo.temperatura
                                  }
                                />
                              ) : (
                                <i
                                  className={
                                    " bx bxs-thermometer font-size-18 text-secondary"
                                  }
                                />
                              )}
                              <ReactTooltip
                                id="tooltip-temperatura"
                                place="top"
                                effect="solid"
                              >
                                Temperatura
                              </ReactTooltip>
                            </span>
                            <span data-tip data-for="tooltip-rolamento">
                              {ativo.condicao_rolamento ? (
                                <i
                                  className={
                                    "bx bx-loader-circle font-size-18 text-" +
                                    ativo.condicao_rolamento +
                                    "me-1"
                                  }
                                />
                              ) : (
                                <i
                                  className={
                                    "bx bx-loader-circle font-size-18 text-secondary me-1"
                                  }
                                />
                              )}
                              <ReactTooltip
                                id="tooltip-rolamento"
                                place="top"
                                effect="solid"
                              >
                                Condição do rolamento
                              </ReactTooltip>
                            </span>
                            <span data-tip data-for="tooltip-vibracao">
                              {ativo.vibracao ? (
                                <i
                                  className={
                                    "dripicons-vibrate font-size-18 text-" +
                                    ativo.vibracao
                                  }
                                />
                              ) : (
                                <i
                                  className={
                                    "dripicons-vibrate font-size-18 text-secondary"
                                  }
                                />
                              )}
                              <ReactTooltip
                                id="tooltip-vibracao"
                                place="top"
                                effect="solid"
                              >
                                Vibração
                              </ReactTooltip>
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>

            {/* Botões de paginação */}
            <Row>
              <Col
                lg="12"
                className="pagination pagination-rounded justify-content-center mb-2"
              >
                <Pagination
                  activePage={activePage}
                  totalItemsCount={ativosTotal}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </Col>
            </Row>
          </>
        </Container>
      </div>
    </Layout>
  );
}

export default Ativos;
