import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import api from "../../../services/api";

import Layout from "../../../components/VerticalLayout";

function HistoricoDoEvento() {
  //Recebendo o evento_id de TabelaEventos.js
  let params = useParams();
  //setando variáveis de estado para exibir a tabela de evento_log e os detalhes do evento
  const [tabelaEventoLog, settabelaEventoLog] = useState([]);
  const [evento, setEvento] = useState([]);

  function formataData(data) {
    let dataISO = moment.utc(data).format();
    let dataConvertida = new Date(dataISO).toLocaleString("pt-BR", {
      timezone: "America/Sao_Paulo",
    });
    return dataConvertida;
  }

  useEffect(() => {
    //conexão com a API
    const token = localStorage.getItem("token");
    try {
      api
        .get(`/eventos/${params.eventoId}/listar-historico-do-evento`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setEvento(response.data.evento);
          settabelaEventoLog(response.data.logs);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      alert(e.message);
    }
  }, [params.eventoId]);

  /*Setando ícones conforme tipo do log de evento */

  function IconeLog(tipo) {
    const bxIcone = tipo;

    if (bxIcone === "Evento criado") {
      return <i className={"bx bx-plus-circle h2 text-primary"} />;
    }
    if (bxIcone === "Log de trabalho") {
      return <i className={"bx bx-notepad h2 text-primary"} />;
    }
    if (bxIcone === "Log de resolução") {
      return <i className={"bx bx-badge-check h2 text-success"} />;
    }

    return <i className={"bx bx-notepad h2 text-primary"} />;
  }

  document.title =
    "Histórico do Evento | Microméros - AS3 Engenharia Elétrica RJ";
  return (
    <Layout>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xs="12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Histórico do evento</h4>
                <div className="page-title-right">
                  <Breadcrumb listClassName="m-0">
                    <BreadcrumbItem>
                      <Link to="/eventos">Lista de Eventos</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">Histórico do evento</Link>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <p className="font-size-17">Informações</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <div>
                        {evento.map((evento) => (
                          <div key={evento.id}>
                            <p>
                              <span className="font-weight-semibold">
                                Cód. do evento:{" "}
                              </span>{" "}
                              {evento.id}{" "}
                            </p>
                            <p>
                              <span className="font-weight-semibold">
                                Data do evento:{" "}
                              </span>{" "}
                              {formataData(evento.data_deteccao)}{" "}
                            </p>
                            <p>
                              <span className="font-weight-semibold">
                                Tipo:{" "}
                                <Badge
                                  className={
                                    "font-size-12 badge rounded-pill bg-" +
                                    evento.severidade
                                  }
                                  pill
                                >
                                  {" "}
                                  {evento.severidade}
                                </Badge>{" "}
                              </span>{" "}
                            </p>
                            <p>
                              <span className="font-weight-semibold">
                                Nome do gatilho:
                              </span>{" "}
                              {evento.gatilho_nome}
                            </p>
                            <p>
                              <span className="font-weight-semibold">
                                Ativo:{" "}
                              </span>
                              <Link
                                to={"/ativo/" + evento.ativo_id}
                                className="text-decoration-underline"
                              >
                                {evento.ativo_nome}
                              </Link>
                            </p>
                            <p>
                              <span className="font-weight-semibold">
                                Sensor:{" "}
                              </span>
                              {evento.sensor_nome}
                            </p>
                            <p>
                              <span className="font-weight-semibold">
                                Status:{" "}
                              </span>
                              <Badge
                                className={
                                  "font-size-12 badge rounded-pill bg-" +
                                  evento.status
                                }
                                pill
                              >
                                {" "}
                                {evento.status}
                              </Badge>{" "}
                            </p>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle>Histórico</CardTitle>

                  <div className="">
                    <ul className="verti-timeline list-unstyled">
                      {/* Mapeando tabela evento_log */}
                      {tabelaEventoLog.map((eventolog, key) => (
                        <li key={key} className="event-list">
                          {/*Colocando a setinha para cada registro */}
                          <div className="event-timeline-dot">
                            <i
                              className={
                                eventolog.id === tabelaEventoLog.length
                                  ? "bx bx-right-arrow-circle bx-fade-right"
                                  : "bx bx-right-arrow-circle"
                              }
                            />
                          </div>
                          <div className="media">
                            {/*Setando ícones conforme tipo do log de evento */}
                            <div className="me-3">
                              {IconeLog(eventolog.tipo)}
                            </div>
                            <div className="media-body">
                              <div>
                                {/*TODO: Deixar o toLocaleStringString dinâmico */}
                                <h5>
                                  {formataData(eventolog.created_at) +
                                    " - " +
                                    eventolog.tipo}
                                </h5>
                                <p className="text-muted">
                                  {eventolog.comentario}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default HistoricoDoEvento;
