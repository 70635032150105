import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Media } from "reactstrap";

const CardEventos = (props) => {
  return (
    <Col lg="2">
      <Card outline color={props.color} className="border">
        <CardBody>
          <Media className="justify-content-center mt-2">
            <p className="card-title text-muted font-size-14">
              <i
                className={
                  "mdi mdi-brightness-1 font-size-14 align-middle me-1 text-" +
                  props.color
                }
              />
              {props.eventoTipo}
            </p>
          </Media>
          <Media className="justify-content-center ">
            <p>{props.eventoQuantidade}</p>
          </Media>
        </CardBody>
      </Card>
    </Col>
  );
};

CardEventos.propTypes = {
  color: PropTypes.string,
  eventoTipo: PropTypes.string,
  eventoQuantidade: PropTypes.number,
};

export default CardEventos;
