import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../../components/VerticalLayout";
import CardEventos from "./CardEventos";
import CardAtivoInformacoes from "./CardAtivoInformacoes";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Container, Row } from "reactstrap";
import api from "../../../../services/api";
import NavInformacoesAtivo from "./NavInformacoesAtivo";

function InformacoesAtivos() {
  let params = useParams();

  const [ativo, setAtivo] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      api
        .get(`/ativos/${params.ativoId}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setAtivo(response.data.ativo);
        })
        .catch((err) => {});
    } catch (e) {}
  }, [params.ativoId]);

  document.title =
    "Informações do ativo | Microméros - AS3 Engenharia Elétrica RJ";

  return (
    <Layout>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Monitoramento dos ativos"
            breadcrumbItem="Informações do ativo"
            link="/ativos"
          />

          <Row>
            <CardAtivoInformacoes
              fotoAtivo={null}
              ativoNome={ativo.ativo_nome}
              grupoAtivosNome={ativo.grupo_ativos_nome}
              tagNome={ativo.tag_nome}
            />
            <CardEventos
              color="warning"
              eventoTipo={"Alerta"}
              eventoQuantidade={ativo.alertas}
            />
            <CardEventos
              color="danger"
              eventoTipo={"Alarme"}
              eventoQuantidade={ativo.alarmes}
            />
            <CardEventos
              color="primary"
              eventoTipo={"Manutenção"}
              eventoQuantidade={ativo.manutencao}
            />
          </Row>

          <NavInformacoesAtivo ativo={ativo} />
        </Container>
      </div>
    </Layout>
  );
}

export default InformacoesAtivos;
