import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import {
  Button,
  Container,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const AdicionarGrupoUsuarios = (props) => {
  let params = useParams();
  const { isOpen, toggle, grupoativoid } = props;
  const [grupoUsuariosId, setGrupoUsuariosId] = useState();

  function handleGrupoUsuarios(grupo) {
    setGrupoUsuariosId(grupo.value);
  }

  const opcoesGrupos = (inputValue) => {
    const token = localStorage.getItem("token");
    return api
      .get(
        `/gerenciamento/grupos-de-ativos/${params.grupoAtivosId}/grupos-de-usuarios`,
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        const res = response.data.grupos_usuarios.map((grupo) => ({
          value: grupo.grupo_usuarios_id,
          label: grupo.grupo_usuarios_nome,
        }));
        return res;
      })
      .catch((error) => {
        console.error("Ops! ocorreu um erro: " + error);
      });
  };

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");
    api
      .post(
        `/gerenciamento/grupos-de-ativos/${params.grupoAtivosId}/grupos-de-usuarios`,
        {
          grupoUsuariosId,
        },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setGrupoUsuariosId();
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          toast.error(error.response.data.mensagem_erro, {
            autoClose: 3000,
          });
        }
      });
  }

  return (
    <Modal
      id="adicionar-grupo-usuarios"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
      grupoativoid={grupoativoid}
    >
      <Container>
        <ModalHeader>Adicionar grupo de usuários</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <Label className=" text-dark">Nome do grupo:</Label>
            <AsyncSelect
              className="text-dark"
              cacheOptions
              defaultOptions
              name="grupoUsuarios"
              onChange={handleGrupoUsuarios}
              loadOptions={opcoesGrupos}
              placeholder="Selecione o grupo"
              classNamePrefix="select2-selection"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="link"
              onClick={() => {
                setGrupoUsuariosId();
                toggle();
              }}
            >
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Container>
    </Modal>
  );
};

AdicionarGrupoUsuarios.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  grupoativoid: PropTypes.any,
};

export default AdicionarGrupoUsuarios;
