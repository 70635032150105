import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  CardTitle,
  FormGroup,
  Label,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import api from "../../../services/api";

function GatilhosFiltro(props) {
  const {
    setPlanta,
    setGrupoAtivos,
    setAtivo,
    setNomeGatilhos,
    nomeGatilhos,
    activePage,
    planta,
    grupoAtivos,
    ativo,
    setGatilhos,
    setGatilhosTotal,
  } = props;

  //Listagem de Plantas
  const opcoesPlanta = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/plantas/lista-de-plantas`, {
        headers: { Authorization: token },
      });
      const res = response.data.plantas.map((planta) => ({
        value: planta.planta_nome,
        label: planta.planta_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Select -> Listagem de Grupos
  const opcoesGrupo = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/grupos-de-ativos`, {
        headers: { Authorization: token },
      });
      const res = response.data.grupos_ativos.map((grupo) => ({
        value: grupo.grupo_nome,
        label: grupo.grupo_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Select -> Listagem de Ativos
  const opcoesAtivo = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/ativos/lista-de-ativos`, {
        headers: { Authorization: token },
      });
      const res = response.data.ativos.map((ativo) => ({
        value: ativo.ativo_nome,
        label: ativo.ativo_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Select -> Listagem de Gatilhos
  const opcoesNomeGatilhos = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/gatilhos/lista-de-gatilhos`, {
        headers: { Authorization: token },
      });
      const res = response.data.gatilhos.map((gatilho) => ({
        value: gatilho.gatilho_nome,
        label: gatilho.gatilho_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  function handlePlanta(planta) {
    setPlanta(planta);
  }

  function handleGrupo(grupoAtivos) {
    setGrupoAtivos(grupoAtivos);
  }

  function handleAtivo(ativo) {
    setAtivo(ativo);
  }

  function handleNomeGatilhos(gatilho) {
    setNomeGatilhos(gatilho);
  }

  //Limpar filtros
  function limpaFiltros() {
    setPlanta([]);
    setGrupoAtivos([]);
    setAtivo([]);
    setNomeGatilhos([]);
  }

  function handleSubmit(event) {
    event.preventDefault();

    const token = localStorage.getItem("token");
    try {
      //listagem dos gatilhos
      api
        .post(
          `/gerenciamento/gatilhos?page=${activePage}`,
          {
            planta,
            grupoAtivos,
            ativo,
            nomeGatilhos,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setGatilhos(response.data.gatilhos.data);
          setGatilhosTotal(response.data.gatilhos.total);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>Filtros</CardTitle>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg="6">
              <div className="mb-3 select2-container">
                <FormGroup className="mb-4">
                  <Label className=" text-dark">Planta</Label>
                  <div className="col-md-12">
                    <AsyncSelect
                      className="text-dark"
                      cacheOptions
                      defaultOptions
                      name="planta"
                      onChange={handlePlanta}
                      loadOptions={opcoesPlanta}
                      placeholder="Selecione a planta"
                      classNamePrefix="select2-selection"
                      value={planta}
                      isMulti
                    />
                  </div>
                </FormGroup>
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3 select2-container">
                <Label className=" text-dark">Grupo</Label>
                <AsyncSelect
                  className="text-dark"
                  cacheOptions
                  defaultOptions
                  name="grupoAtivos"
                  onChange={handleGrupo}
                  loadOptions={opcoesGrupo}
                  placeholder="Selecione o grupo de ativos"
                  classNamePrefix="select2-selection"
                  value={grupoAtivos}
                  isMulti
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <div className="mb-3 select2-container">
                <Label className=" text-dark">Ativo</Label>
                <AsyncSelect
                  className="text-dark"
                  cacheOptions
                  defaultOptions
                  name="ativo"
                  onChange={handleAtivo}
                  loadOptions={opcoesAtivo}
                  placeholder="Selecione o ativo"
                  classNamePrefix="select2-selection"
                  value={ativo}
                  isMulti
                />
              </div>
            </Col>

            <Col lg="6">
              <div className="mb-3 select2-container">
                <Label className=" text-dark">Nome do gatilho</Label>
                <AsyncSelect
                  className="text-dark"
                  cacheOptions
                  defaultOptions
                  name="nome_gatilho"
                  onChange={handleNomeGatilhos}
                  loadOptions={opcoesNomeGatilhos}
                  placeholder="Selecione o gatilho"
                  classNamePrefix="select2-selection"
                  value={nomeGatilhos}
                  isMulti
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12" className="text-center">
              <Button color="link" type="button" onClick={limpaFiltros}>
                Limpar filtros
              </Button>

              <Button color="primary" type="submit">
                Filtrar
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

GatilhosFiltro.propTypes = {
  setPlanta: PropTypes.func,
  setGrupoAtivos: PropTypes.func,
  setAtivo: PropTypes.func,
  setNomeGatilhos: PropTypes.func,
  nomeGatilhos: PropTypes.any,
  activePage: PropTypes.number,
  planta: PropTypes.any,
  grupoAtivos: PropTypes.any,
  ativo: PropTypes.any,
  setGatilhos: PropTypes.any,
  setGatilhosTotal: PropTypes.any,
};

export default GatilhosFiltro;
