import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
} from "reactstrap";
import api from "../../../../services/api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

toast.configure();

const AdicionarAtivosGrupoAtivosModal = (props) => {
  const { isOpen, toggle, grupoativoid } = props;
  let params = useParams();
  const [ativo, setAtivo] = useState();

  function handleAtivos(ativo) {
    setAtivo(ativo.value);
  }

  const opcoesAtivos = (inputValue) => {
    const token = localStorage.getItem("token");
    return api
      .get(
        `gerenciamento/grupos-de-ativos/${params.grupoAtivosId}/adicionar-ativo`,
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        const res = response.data.ativos.map((ativo) => ({
          value: ativo.ativo_id,
          label: ativo.ativo_nome + " (Grupo atual: " + ativo.grupo_nome + ")",
        }));
        return res;
      })
      .catch((error) => {
        console.error("Ops! ocorreu um erro: " + error);
      });
  };

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");
    let grupoAtivosDestino = params.grupoAtivosId;
    api
      .put(
        `/gerenciamento/grupos-de-ativos/${params.grupoAtivosId}/adicionar-ativo/ativo/${ativo}`,
        {
          grupoAtivosDestinoId: grupoAtivosDestino,
        },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setAtivo();
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          toast.error(error.response.data.mensagem_erro, {
            autoClose: 3000,
          });
        }
      });
  }

  return (
    <Modal
      id="adicionar-ativos-grupo-ativos"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      //size="lg"
      className="modal-dialog-centered"
      toggle={toggle}
      grupoativoid={grupoativoid}
    >
      <div className="modal-content">
        <ModalHeader>Adicionar ativo ao grupo</ModalHeader>
        <form onSubmit={onSubmit}>
          <ModalBody>
            <Label className=" text-dark">Nome do ativo:</Label>
            <AsyncSelect
              className="text-dark"
              cacheOptions
              defaultOptions
              name="ativos"
              onChange={handleAtivos}
              loadOptions={opcoesAtivos}
              placeholder="Selecione o ativo"
              classNamePrefix="select2-selection"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="link"
              onClick={() => {
                setAtivo();
                toggle();
              }}
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </div>
    </Modal>
  );
};

AdicionarAtivosGrupoAtivosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  grupoativoid: PropTypes.any,
};

export default AdicionarAtivosGrupoAtivosModal;
