import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";

toast.configure();

function EditarPermissaoUsuarioModal(props) {
  const { isOpen, toggle, usuarioid, grupousuariosid } = props;

  const [isModerator, setIsModerator] = useState(null);

  useEffect(() => {
    if (grupousuariosid && usuarioid) {
      const token = localStorage.getItem("token");
      api
        .get(
          `/gerenciamento/grupos-de-usuarios/${grupousuariosid}/usuarios/${usuarioid}/permissao`,
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setIsModerator(response.data.is_moderator);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [usuarioid]);

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");
    api
      .post(
        `/gerenciamento/grupos-de-usuarios/${grupousuariosid}/usuarios/${usuarioid}/permissao`,
        {
          isModerator,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setIsModerator(response.data.is_moderator);
        toggle();
        toast.success(response.data.mensagem, {
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function Cancelar() {
    setIsModerator(null);
    toggle();
  }
  return (
    <Modal
      id="editar-permissao-usuario-do-grupo-modal"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
      usuarioid={usuarioid}
    >
      <ModalHeader>Editar Permissão do Usuário</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="membro"
              id="radio-input-membro"
              checked={isModerator === 0}
              onClick={() => setIsModerator(0)}
              readOnly
            />
            <Link
              to="#"
              onClick={() => setIsModerator(0)}
              className="text-dark"
            >
              <label className="form-check-label" htmlFor="exampleRadios1">
                Membro
              </label>
            </Link>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="moderador"
              id="radio-input-moderador"
              checked={isModerator === 1}
              onClick={() => setIsModerator(1)}
              readOnly
            />
            <Link
              to="#"
              onClick={() => setIsModerator(1)}
              className="text-dark"
            >
              <label className="form-check-label" htmlFor="exampleRadios2">
                Moderador
              </label>
            </Link>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="link" onClick={Cancelar}>
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

EditarPermissaoUsuarioModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  usuarioid: PropTypes.any,
  grupousuariosid: PropTypes.any,
};
export default EditarPermissaoUsuarioModal;
