import { useEffect, useState } from "react";
import { Button, CardTitle, Col, Row, Table } from "reactstrap";
import CriarGatilhoModal from "../../../../../components/Common/Modais/CriarGatilhoModal";
import EditarGatilhoModal from "../../../../../components/Common/Modais/EditarGatilhoModal";
import ExcluirGatilhoModal from "../../../../../components/Common/Modais/ExcluirGatilhoModal";
import Pagination from "react-js-pagination";
import { useParams } from "react-router-dom";
import api from "../../../../../services/api";
import { map } from "lodash";
import ReactTooltip from "react-tooltip";

function Tab4(props) {
  let params = useParams();

  const [gatilhos, setGatilhos] = useState([]);
  const [alerta, setAlerta] = useState(0);
  const [alarme, setAlarme] = useState(0);
  const [gatilhoid, setGatilhoId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(0);

  const [activePage, setActivePage] = useState(1);
  const [gatilhosTotal, setGatilhosTotal] = useState(0);

  //MODAIS
  const [abrirCriarGatilhoModal, setAbrirCriarGatilhoModal] = useState(false);
  const [abrirEditarGatilhoModal, setAbrirEditarGatilhoModal] = useState(false);
  const [abrirExcluirGatilhoModal, setAbrirExcluirGatilhoModal] =
    useState(false);

  function toggleCriarGatilhoModal() {
    setAbrirCriarGatilhoModal(!abrirCriarGatilhoModal);
  }
  function toggleEditarGatilhoModal(gatilhoID, alerta, alarme) {
    setGatilhoId(gatilhoID);
    setAlerta(alerta);
    setAlarme(alarme);
    setAbrirEditarGatilhoModal(!abrirEditarGatilhoModal);
  }

  function toggleExcluirGatilhoModal(gatilhoID) {
    setGatilhoId(gatilhoID);
    setAbrirExcluirGatilhoModal(!abrirExcluirGatilhoModal);
  }

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  useEffect(() => {
    //conexão com a API
    const token = localStorage.getItem("token");
    try {
      //listagem dos gatilhos
      api
        .get(`/ativos/${params.ativoId}/gatilhos?page=${activePage}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setGatilhos(response.data.gatilhos.data);
          setGatilhosTotal(response.data.gatilhos.total);
          setIsAdmin(response.data.is_admin);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      alert(e.message);
    }
  }, [
    abrirCriarGatilhoModal,
    abrirEditarGatilhoModal,
    abrirExcluirGatilhoModal,
  ]);

  return (
    <>
      <Row lg="12" className="mt-4">
        <Row>
          <Col lg="12">
            <CardTitle className="mb-3">
              Listagem de Gatilhos
              {isAdmin === 1 ? (
                <Button
                  type="button"
                  color="primary"
                  className="ms-3"
                  onClick={() => {
                    toggleCriarGatilhoModal();
                  }}
                >
                  Criar novo gatilho
                </Button>
              ) : null}
              {abrirCriarGatilhoModal ? (
                <CriarGatilhoModal
                  isOpen={abrirCriarGatilhoModal}
                  toggle={toggleCriarGatilhoModal}
                  ativoid={params.ativoId}
                />
              ) : null}
            </CardTitle>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            {gatilhos.length > 0 ? (
              <Col lg="12" className="table-responsive mb-3">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Nome do gatilho</th>
                      <th>Alerta</th>
                      <th>Alarme</th>
                      {isAdmin === 1 ? <th>Ações</th> : null}
                    </tr>
                  </thead>

                  {map(gatilhos, (gatilho) => (
                    <tbody key={gatilho.gatilho_id}>
                      <tr>
                        <td>{gatilho.gatilho_nome}</td>
                        <td>{gatilho.alerta}</td>
                        <td>{gatilho.alarme}</td>
                        {isAdmin === 1 ? (
                          <td>
                            <div className="d-flex gap-3">
                              <span>
                                <Button
                                  type="button"
                                  className="btn-rounded"
                                  color="light"
                                  data-tip
                                  data-for="tooltip-editar"
                                  onClick={() => {
                                    toggleEditarGatilhoModal(
                                      gatilho.gatilho_id,
                                      gatilho.alerta,
                                      gatilho.alarme
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit font-size-14" />
                                </Button>
                                <ReactTooltip
                                  id="tooltip-editar"
                                  place="top"
                                  effect="solid"
                                >
                                  Editar
                                </ReactTooltip>
                              </span>
                              <span>
                                <Button
                                  type="button"
                                  className="btn-rounded"
                                  color="light"
                                  data-tip
                                  data-for="tooltip-excluir"
                                  onClick={() => {
                                    toggleExcluirGatilhoModal(
                                      gatilho.gatilho_id
                                    );
                                  }}
                                >
                                  <i className="bx bx-x font-size-14" />
                                  {""}
                                </Button>
                                <ReactTooltip
                                  id="tooltip-excluir"
                                  place="top"
                                  effect="solid"
                                >
                                  Excluir
                                </ReactTooltip>
                              </span>
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                  ))}
                  {abrirEditarGatilhoModal ? (
                    <EditarGatilhoModal
                      isOpen={abrirEditarGatilhoModal}
                      toggle={toggleEditarGatilhoModal}
                      gatilhoid={gatilhoid}
                      alerta={alerta}
                      alarme={alarme}
                      setAlerta={setAlerta}
                      setAlarme={setAlarme}
                    />
                  ) : null}
                  {abrirExcluirGatilhoModal ? (
                    <ExcluirGatilhoModal
                      isOpen={abrirExcluirGatilhoModal}
                      toggle={toggleExcluirGatilhoModal}
                      gatilhoid={gatilhoid}
                    />
                  ) : null}
                </Table>
              </Col>
            ) : (
              <>
                <Row>
                  <Col lg="12">
                    <h5 className="text-center mb-5">
                      <span className="badge rounded-pill bg-primary font-weight-bold font-size-14 ">
                        i
                      </span>{" "}
                      Não há Gatilhos cadastrados.
                    </h5>
                  </Col>
                </Row>
              </>
            )}

            {/* Botões de paginação */}
            <Row>
              <Col
                lg="12"
                className="pagination pagination-rounded justify-content-center"
              >
                <Pagination
                  activePage={activePage}
                  totalItemsCount={gatilhosTotal}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  itemsCountPerPage={10}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  );
}
export default Tab4;
