import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import Layout from "../../../components/VerticalLayout";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import api from "../../../services/api";
import Pagination from "react-js-pagination";
import ReactTooltip from "react-tooltip";
import { map } from "lodash";
import GatilhosFiltro from "./GatilhosFiltro";
import EditarGatilhoModal from "../../../components/Common/Modais/EditarGatilhoModal";
import ExcluirGatilhoModal from "./../../../components/Common/Modais/ExcluirGatilhoModal";

function Gatilhos() {
  const [planta, setPlanta] = useState();
  const [grupoAtivos, setGrupoAtivos] = useState([]);
  const [ativo, setAtivo] = useState([]);
  const [nomeGatilhos, setNomeGatilhos] = useState([]);
  const [gatilhos, setGatilhos] = useState([]);
  const [alerta, setAlerta] = useState(0);
  const [alarme, setAlarme] = useState(0);
  const [gatilhoid, setGatilhoId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(0);
  //paginação
  const [activePage, setActivePage] = useState(1);
  const [gatilhosTotal, setGatilhosTotal] = useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  //Modal editar gatilho
  const [abrirEditarGatilhoModal, setAbrirEditarGatilhoModal] = useState(false);
  const [abrirExcluirGatilhoModal, setAbrirExcluirGatilhoModal] =
    useState(false);

  function toggleEditarGatilhoModal(gatilhoID, alerta, alarme) {
    setGatilhoId(gatilhoID);
    setAlerta(alerta);
    setAlarme(alarme);
    setAbrirEditarGatilhoModal(!abrirEditarGatilhoModal);
  }

  function toggleExcluirGatilhoModal(gatilhoID, alerta, alarme) {
    setGatilhoId(gatilhoID);
    setAbrirExcluirGatilhoModal(!abrirExcluirGatilhoModal);
  }

  useEffect(() => {
    //conexão com a API
    const token = localStorage.getItem("token");
    try {
      //listagem dos gatilhos
      api
        .post(
          `/gerenciamento/gatilhos?page=${activePage}`,
          {
            planta,
            grupoAtivos,
            ativo,
            nomeGatilhos,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setGatilhos(response.data.gatilhos.data);
          setGatilhosTotal(response.data.gatilhos.total);
          setIsAdmin(response.data.is_admin);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      alert(e.message);
    }
  }, [activePage, abrirEditarGatilhoModal, abrirExcluirGatilhoModal]);

  document.title = "Gatilhos | Microméros - AS3 Engenharia Elétrica RJ";
  return (
    <Layout>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Configurações"
            breadcrumbItem="Lista de gatilhos"
          />
          <Row>
            <Col lg="12">
              <GatilhosFiltro
                setPlanta={setPlanta}
                setGrupoAtivos={setGrupoAtivos}
                setAtivo={setAtivo}
                setNomeGatilhos={setNomeGatilhos}
                nomeGatilhos={nomeGatilhos}
                activePage={activePage}
                planta={planta}
                grupoAtivos={grupoAtivos}
                ativo={ativo}
                setGatilhos={setGatilhos}
                setGatilhosTotal={setGatilhosTotal}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {gatilhos.length > 0 ? (
                    <Col lg="12" className="table-responsive mb-3">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Planta</th>
                            <th>Grupo de ativos</th>
                            <th>Ativo</th>
                            <th>Nome do gatilho</th>
                            <th>Medida</th>
                            <th>Alerta</th>
                            <th>Alarme</th>
                            {isAdmin === 1 ? <th>Ações</th> : null}
                          </tr>
                        </thead>
                        {map(gatilhos, (gatilho, index) => (
                          <tbody key={gatilho.gatilho_id}>
                            <tr>
                              <td>{gatilho.planta_nome}</td>
                              <td>{gatilho.grupo_ativos_nome}</td>
                              <td>{gatilho.ativo_nome}</td>
                              <td>{gatilho.gatilho_nome}</td>
                              <td>{gatilho.medida_nome}</td>
                              <td>{gatilho.alerta}</td>
                              <td>{gatilho.alarme}</td>
                              {isAdmin === 1 ? (
                                <td>
                                  <span className="d-flex gap-3">
                                    <span>
                                      <Button
                                        type="button"
                                        className="btn-rounded"
                                        color="light"
                                        data-tip
                                        data-for="tooltip-editar-2"
                                        onClick={() => {
                                          toggleEditarGatilhoModal(
                                            gatilho.gatilho_id,
                                            gatilho.alerta,
                                            gatilho.alarme
                                          );
                                        }}
                                      >
                                        <i className="bx bx-edit font-size-14" />
                                      </Button>
                                      <ReactTooltip
                                        id="tooltip-editar-2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Editar
                                      </ReactTooltip>
                                    </span>
                                    <span>
                                      <Button
                                        type="button"
                                        className="btn-rounded"
                                        color="light"
                                        data-tip
                                        data-for="tooltip-excluir-2"
                                        onClick={() => {
                                          toggleExcluirGatilhoModal(
                                            gatilho.gatilho_id
                                          );
                                        }}
                                      >
                                        <i className="bx bx-x font-size-14" />
                                        {""}
                                      </Button>
                                      <ReactTooltip
                                        id="tooltip-excluir-2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Excluir
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                </td>
                              ) : null}
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </Col>
                  ) : (
                    <>
                      <Row>
                        <Col lg="12">
                          <h5 className="text-center mb-5">
                            <span className="badge rounded-pill bg-primary font-weight-bold font-size-14 ">
                              i
                            </span>{" "}
                            Não há Gatilhos cadastrados.
                          </h5>
                        </Col>
                      </Row>
                    </>
                  )}
                  {gatilhoid != null ? (
                    <>
                      <EditarGatilhoModal
                        isOpen={abrirEditarGatilhoModal}
                        toggle={toggleEditarGatilhoModal}
                        gatilhoid={gatilhoid}
                        alerta={alerta}
                        alarme={alarme}
                        setAlerta={setAlerta}
                        setAlarme={setAlarme}
                      />
                      <ExcluirGatilhoModal
                        isOpen={abrirExcluirGatilhoModal}
                        toggle={toggleExcluirGatilhoModal}
                        gatilhoid={gatilhoid}
                      />
                    </>
                  ) : null}

                  {/* Botões de paginação */}
                  <Row>
                    <Col
                      lg="12"
                      className="pagination pagination-rounded justify-content-center"
                    >
                      <Pagination
                        activePage={activePage}
                        totalItemsCount={gatilhosTotal}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemsCountPerPage={10}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}
export default Gatilhos;
