import React, { useEffect, useState } from "react";
import CriarNovoGrupoAtivosModal from "./modais/CriarNovoGrupoAtivosModal";
import ExcluirGrupoAtivoModal from "./modais/ExcluirGrupoAtivoModal";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import api from "../../../services/api";
import { Button, Col, Row, Table } from "reactstrap";
import { map } from "lodash";
import { toast } from "react-toastify";

toast.configure();

function TabelaGruposDeAtivos() {
  const [grupoativoid, setGrupoativoid] = useState();
  const [grupos, setGrupos] = useState([]);
  //paginação
  const [activePage, setActivePage] = useState(1);
  const [gruposTotal, setGruposTotal] = useState(0);
  const [isAdmin, setIsAdmin] = useState(0);

  const [abrirCriarGrupoAtivosModal, setAbrirCriarGrupoAtivosModal] =
    useState(false);

  const [abrirExcluirGrupoAtivoModal, setAbrirExcluirGrupoAtivoModal] =
    useState(false);

  function toggleModalCriarGrupoAtivos() {
    setAbrirCriarGrupoAtivosModal(!abrirCriarGrupoAtivosModal);
  }

  function toggleExcluirGrupoAtivoModal(grupoativoid) {
    setAbrirExcluirGrupoAtivoModal(!abrirExcluirGrupoAtivoModal);
    setGrupoativoid(grupoativoid);
  }

  useEffect(() => {
    //conexão com a API
    const token = localStorage.getItem("token");
    try {
      //listagem dos grupos de ativos
      api
        .get(`/gerenciamento/grupos-de-ativos?page=${activePage}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setGrupos(response.data.grupos_ativos.data);
          setGruposTotal(response.data.grupos_ativos.total);
          setIsAdmin(response.data.is_admin);
        })
        .catch((error) => {
          if (error.response.data.mensagem_erro) {
            toast.error(error.response.data.mensagem_erro, {
              autoClose: 3000,
            });
          }
        });
    } catch (e) {
      alert(e.message);
    }
  }, [activePage, abrirExcluirGrupoAtivoModal, abrirCriarGrupoAtivosModal]);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  return (
    <>
      {isAdmin === 1 ? (
        <Row className="mb-4">
          <Col lg={12} sm={12}>
            <Button color="primary" onClick={toggleModalCriarGrupoAtivos}>
              Criar grupo de ativos
            </Button>

            <CriarNovoGrupoAtivosModal
              isOpen={abrirCriarGrupoAtivosModal}
              toggle={toggleModalCriarGrupoAtivos}
            />
            <ExcluirGrupoAtivoModal
              toggle={toggleExcluirGrupoAtivoModal}
              isOpen={abrirExcluirGrupoAtivoModal}
              grupoativoid={grupoativoid}
            />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Table className="table mb-0">
          <thead>
            <tr>
              <th>Nome do Grupo</th>
              <th>Planta</th>
              <th>Frequência de notificações</th>
              <th>Ações</th>
            </tr>
          </thead>
          {map(grupos, (grupo, key) => (
            <tbody key={grupo.grupo_ativos_id}>
              <tr>
                <td>{grupo.grupo_ativos_nome}</td>
                <td>{grupo.planta}</td>
                <td>
                  {grupo.frequencia === null ? (
                    <>Desativada</>
                  ) : grupo.frequencia === 60 ? (
                    <> {grupo.frequencia / 60} hora</>
                  ) : (
                    <> {grupo.frequencia / 60} horas</>
                  )}
                </td>
                <td>
                  {" "}
                  <>
                    <span className="d-flex gap-3">
                      {/*Editar Grupo de ativos*/}
                      <Link
                        to={
                          "/gerenciamento/grupos-de-ativos/" +
                          grupo.grupo_ativos_id
                        }
                      >
                        <Button
                          type="button"
                          className="btn-rounded"
                          color="light"
                          data-tip
                          data-for="tooltip-editar-grupo-de-ativos"
                        >
                          <i className="mdi mdi-eye-outline font-size-14" />
                        </Button>
                        <ReactTooltip
                          id="tooltip-editar-grupo-de-ativos"
                          place="top"
                          effect="solid"
                        >
                          Visualizar
                        </ReactTooltip>
                      </Link>
                      {grupo.is_admin === 1 && grupo.is_default !== 1 ? (
                        <>
                          <Button
                            type="button"
                            className="btn-rounded"
                            color="light"
                            data-tip
                            data-for="tooltip-excluir-grupo-de-ativos"
                            onClick={() => {
                              toggleExcluirGrupoAtivoModal(
                                grupo.grupo_ativos_id
                              );
                            }}
                          >
                            <i className="bx bx-x font-size-14" />
                            {""}
                          </Button>
                          <ReactTooltip
                            id="tooltip-excluir-grupo-de-ativos"
                            place="top"
                            effect="solid"
                          >
                            Excluir
                          </ReactTooltip>
                        </>
                      ) : null}
                    </span>
                  </>
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </Row>

      {/* Botões de paginação */}
      <Row>
        <Col
          lg="12"
          className="pagination pagination-rounded justify-content-center mb-2 mt-3"
        >
          <Pagination
            activePage={activePage}
            totalItemsCount={gruposTotal}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
            itemsCountPerPage={5}
          />
        </Col>
      </Row>
    </>
  );
}

export default TabelaGruposDeAtivos;
