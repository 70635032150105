import React, { useEffect, useState } from "react";
import Layout from "../../../components/VerticalLayout";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { useParams } from "react-router-dom";
import api from "../../../services/api";
import { map } from "lodash";
import ReactTooltip from "react-tooltip";
import { Stack } from "react-bootstrap";
import EditarNomeGrupoUsuariosModal from "./modais/EditarNomeGrupoUsuariosModal";
import EditarPermissaoUsuarioModal from "./modais/EditarPermissaoUsuarioModal";
import AdicionarUsuarioGrupoUsuariosModal from "./modais/AdicionarUsuarioGrupoUsuariosModal";
import ExcluirUsuarioGrupoUsuariosModal from "./modais/ExcluirUsuarioGrupoUsuariosModal";

function VisualizarGrupoDeUsuarios() {
  let params = useParams();
  const [grupoId, setGrupoId] = useState(null);
  const [nomeGrupo, setNomeGrupo] = useState("");
  const [planta, setPlanta] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [grupoAtivos, setGrupoAtivos] = useState([]);
  const [usuarioLogado, setUsuarioLogado] = useState(null);
  const [isModerator, setIsModerator] = useState(null);
  const [usuarioId, setUsuarioId] = useState(null);

  //Modais
  const [
    abrirEditarNomeGrupoUsuariosModal,
    setAbrirEditarNomeGrupoUsuariosModal,
  ] = useState(false);

  const [
    abrirAdicionarUsuarioGrupoUsuariosModal,
    setAdicionarUsuarioGrupoUsuariosModal,
  ] = useState(false);

  const [
    abrirExcluirUsuarioGrupoUsuariosModal,
    setExcluirUsuarioGrupoUsuariosModal,
  ] = useState(false);

  const [
    abrirEditarPermissaoUsuarioModal,
    setAbrirEditarPermissaoUsuarioModal,
  ] = useState(false);

  function toggleEditarNomeGrupoUsuariosModal() {
    setAbrirEditarNomeGrupoUsuariosModal(!abrirEditarNomeGrupoUsuariosModal);
  }

  function toggleAdicionarUsuarioGrupoUsuariosModal() {
    setAdicionarUsuarioGrupoUsuariosModal(
      !abrirAdicionarUsuarioGrupoUsuariosModal
    );
  }

  function toggleExcluirUsuarioGrupoUsuariosModal() {
    setExcluirUsuarioGrupoUsuariosModal(!abrirExcluirUsuarioGrupoUsuariosModal);
  }
  function toggleEditarPermissaoUsuarioModal() {
    setAbrirEditarPermissaoUsuarioModal(!abrirEditarPermissaoUsuarioModal);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    api
      .get(`/gerenciamento/grupos-de-usuarios/${params.grupoUsuariosId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setGrupoId(response.data.grupo_usuarios.grupo_usuarios_id);
        setIsAdmin(response.data.grupo_usuarios.is_admin);
        setIsDefault(response.data.grupo_usuarios.is_default);
        setNomeGrupo(response.data.grupo_usuarios.grupo_usuarios_nome);
        setPlanta(response.data.grupo_usuarios.planta);
        setUsuarios(response.data.grupo_usuarios.usuarios);
        setGrupoAtivos(response.data.grupo_usuarios.grupo_ativos);
        setUsuarioLogado(response.data.usuario_logado_email);
        setIsModerator(response.data.grupo_usuarios.is_moderator);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [
    nomeGrupo,
    abrirEditarPermissaoUsuarioModal,
    abrirAdicionarUsuarioGrupoUsuariosModal,
    abrirExcluirUsuarioGrupoUsuariosModal,
  ]);

  document.title =
    " Visualizar Grupo de usuários | Microméros - AS3 Engenharia Elétrica RJ";
  return (
    <Layout>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Grupos de usuários"
            breadcrumbItem="Visualizar Grupo de usuários"
            link="/gerenciamento/grupos-de-usuarios"
          />
          <Row>
            <Col xl="6" lg="6" sm="12" md="6">
              <Card>
                <CardBody>
                  <Stack direction="horizontal" gap={2}>
                    <CardTitle>{nomeGrupo}</CardTitle>{" "}
                    {(isAdmin === 1 || isModerator === 1) && isDefault === 0 ? (
                      <>
                        <Button
                          type="button"
                          className="btn-rounded btn-sm"
                          color="light"
                          data-tip
                          data-for="tooltip-editar-nome-grupo-usuarios"
                          onClick={() => {
                            toggleEditarNomeGrupoUsuariosModal();
                          }}
                        >
                          <i className="bx bx-edit font-size-12" />
                        </Button>
                        <ReactTooltip
                          id="tooltip-editar-nome-grupo-usuarios"
                          place="top"
                          effect="solid"
                        >
                          Editar
                        </ReactTooltip>
                        <EditarNomeGrupoUsuariosModal
                          toggle={toggleEditarNomeGrupoUsuariosModal}
                          isOpen={abrirEditarNomeGrupoUsuariosModal}
                          grupousuariosid={grupoId}
                          setNomeGrupo={setNomeGrupo}
                        />
                      </>
                    ) : null}
                  </Stack>

                  <CardSubtitle>{planta}</CardSubtitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" sm="12" md="6">
              <Card>
                <CardBody>
                  <Stack direction="horizontal" gap={3}>
                    <CardTitle>Usuários</CardTitle>
                    {isAdmin === 1 || isModerator === 1 ? (
                      <>
                        <Button
                          className="ms-auto"
                          color="primary"
                          onClick={() => {
                            toggleAdicionarUsuarioGrupoUsuariosModal();
                          }}
                        >
                          Adicionar usuários
                        </Button>
                        <AdicionarUsuarioGrupoUsuariosModal
                          isOpen={abrirAdicionarUsuarioGrupoUsuariosModal}
                          toggle={toggleAdicionarUsuarioGrupoUsuariosModal}
                          grupousuariosid={grupoId}
                        />
                      </>
                    ) : null}
                  </Stack>
                  <Row>
                    <Col lg="12" className="table-responsive mb-0">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>E-mail</th>
                            <th>Nome</th>
                            <th>Função</th>
                            {isModerator === 1 || isAdmin === 1 ? (
                              <th>Ações</th>
                            ) : null}
                          </tr>
                        </thead>
                        {map(usuarios, (usuario, key) => (
                          <tbody key={usuario.usuario_id}>
                            <tr>
                              <td>{usuario.usuario_email}</td>
                              <td>{usuario.usuario_nome}</td>
                              <td>
                                {usuario.is_admin === 1 ? (
                                  <>
                                    <span>Administrador</span>
                                  </>
                                ) : usuario.is_moderator === 1 ? (
                                  <>
                                    <span>Moderador do grupo</span>
                                  </>
                                ) : (
                                  <>
                                    <span>Membro do grupo</span>
                                  </>
                                )}
                              </td>
                              <td>
                                {(isAdmin === 1 || isModerator === 1) &&
                                usuario.is_admin != 1 &&
                                usuario.usuario_email !== usuarioLogado ? (
                                  <span className="d-flex gap-3">
                                    <Button
                                      type="button"
                                      className="btn-rounded"
                                      color="light"
                                      data-tip
                                      data-for="tooltip-editar-permissao-usuario"
                                      onClick={() => {
                                        setUsuarioId(usuario.usuario_id);
                                        toggleEditarPermissaoUsuarioModal();
                                      }}
                                    >
                                      <i className="bx bx-edit font-size-14" />
                                    </Button>

                                    <ReactTooltip
                                      id="tooltip-editar-permissao-usuario"
                                      place="top"
                                      effect="solid"
                                    >
                                      Editar
                                    </ReactTooltip>

                                    <Button
                                      type="button"
                                      className="btn-rounded"
                                      color="light"
                                      data-tip
                                      data-for="tooltip-excluir-grupo-usuarios"
                                      onClick={() => {
                                        setUsuarioId(usuario.usuario_id);
                                        toggleExcluirUsuarioGrupoUsuariosModal();
                                      }}
                                    >
                                      <i className="bx bx-x font-size-14" />
                                      {""}
                                    </Button>
                                    <ReactTooltip
                                      id="tooltip-excluir-grupo-usuarios"
                                      place="top"
                                      effect="solid"
                                    >
                                      Excluir
                                    </ReactTooltip>
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </Col>
                    <EditarPermissaoUsuarioModal
                      isOpen={abrirEditarPermissaoUsuarioModal}
                      toggle={toggleEditarPermissaoUsuarioModal}
                      usuarioid={usuarioId}
                      grupousuariosid={grupoId}
                    />
                    <ExcluirUsuarioGrupoUsuariosModal
                      isOpen={abrirExcluirUsuarioGrupoUsuariosModal}
                      toggle={toggleExcluirUsuarioGrupoUsuariosModal}
                      usuarioid={usuarioId}
                      grupousuariosid={grupoId}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" sm="12" md="6">
              <Card>
                <CardBody>
                  <CardTitle>Grupos de ativos acessados</CardTitle>
                  <Row>
                    {grupoAtivos.length > 0 ? (
                      <Col lg="12" className="table-responsive mb-0">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Nome</th>
                            </tr>
                          </thead>
                          {map(grupoAtivos, (grupo_ativos, key) => (
                            <tbody key={grupo_ativos.grupo_ativos_id}>
                              <tr>
                                <td>{grupo_ativos.grupo_ativos_nome}</td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      </Col>
                    ) : (
                      <>
                        <Row>
                          <Col lg="12">
                            <p className="text-center mt-2">
                              {" "}
                              <span className="badge rounded-pill bg-primary font-weight-bold font-size-12 me-2">
                                i
                              </span>
                              Não há grupos de ativos para listar.
                            </p>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default VisualizarGrupoDeUsuarios;
