import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Authentication/Login";
import Logout from "./pages/Authentication/Logout";
import ListaDeEventos from "./pages/Monitoramento/ListadeEventos";
import HistoricoDoEvento from "./pages/Monitoramento/ListadeEventos/HistoricoDoEvento";
import Pages404 from "./pages/pages-404";
import PrivateRoute from "./services/auth";
import Ativos from "./pages/Monitoramento/Ativos/Ativos";
import UserProfile from "./pages/Authentication/UserProfile";
import InformacoesAtivo from "./pages/Monitoramento/Ativos/InformacoesAtivos/index.js";
import RecuperarSenha from "./pages/Authentication/RecuperarSenha";
import DefinirNovaSenha from "./pages/Authentication/DefinirNovaSenha";
import Gatilhos from "./pages/Gerenciamento/Gatilhos";
import RelatorioComparativo from "./pages/Relatorios/Comparativo/index";
import CriarSenha from "./pages/Authentication/CriarSenha";
import GruposDeAtivos from "./pages/Gerenciamento/GruposDeAtivos/index";
import VisualizarGrupoDeAtivos from "./pages/Gerenciamento/GruposDeAtivos/VisualizarGrupoDeAtivos";
import GruposDeUsuarios from "./pages/Gerenciamento/GruposDeUsuarios/index";
import VisualizarGrupoDeUsuarios from "./pages/Gerenciamento/GruposDeUsuarios/VisualizarGrupoDeUsuarios";
import DesbloquearConta from "./pages/Authentication/DesbloquearConta";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/criar-senha/:refreshToken"
          element={<CriarSenha />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/recuperar-senha" element={<RecuperarSenha />} />
        <Route
          exact
          path="/redefinir-senha/:refreshToken"
          element={<DefinirNovaSenha />}
        />
        <Route
          exact
          path="/desbloquear-conta/:refreshToken"
          element={<DesbloquearConta />}
        />
        <Route path="*" element={<Pages404 />} />

        <Route element={<PrivateRoute />}>
          <Route exact path="/" element={<Dashboard />} />
          {/*EVENTOS */}
          <Route exact path="/eventos" element={<ListaDeEventos />} />
          <Route
            exact
            path="/evento/:eventoId"
            element={<HistoricoDoEvento />}
          />
          {/*ATIVOS */}
          <Route exact path="/ativos" element={<Ativos />} />
          <Route exact path="/ativo/:ativoId" element={<InformacoesAtivo />} />
          {/* RELATÓRIOS*/}
          <Route
            exact
            path="/relatorios/comparativo"
            element={<RelatorioComparativo />}
          />
          {/*PERFIL */}
          <Route exact path="/profile" element={<UserProfile />} />
          <Route exact path="/logout" element={<Logout />} />
          {/*GERENCIAMENTO*/}
          <Route exact path="/gerenciamento/gatilhos" element={<Gatilhos />} />
          <Route
            exact
            path="/gerenciamento/grupos-de-ativos"
            element={<GruposDeAtivos />}
          />
          <Route
            exact
            path="/gerenciamento/grupos-de-ativos/:grupoAtivosId"
            element={<VisualizarGrupoDeAtivos />}
          />
          <Route
            exact
            path="/gerenciamento/grupos-de-usuarios"
            element={<GruposDeUsuarios />}
          />
          <Route
            exact
            path="/gerenciamento/grupos-de-usuarios/:grupoUsuariosId"
            element={<VisualizarGrupoDeUsuarios />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
