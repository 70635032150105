import React, { useState } from "react";
import Layout from "../../../components/VerticalLayout";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Table,
  Row,
} from "reactstrap";
import GraficoComparativo from "./GraficoComparativo";
import AsyncSelect from "react-select/async";
import api from "./../../../services/api";

//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function toStartDateString(date) {
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    "00:00:00"
  );
}

function toEndDateString(date) {
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    "23:59:59"
  );
}

function RelatorioComparativo() {
  const [count, setCount] = useState(1);
  const [medida, setMedida] = useState([]);
  const [selectAtivo, setSelectAtivo] = useState([]);
  const [selectAtivo2, setSelectAtivo2] = useState([]);
  const [selectAtivo3, setSelectAtivo3] = useState([]);
  const [selectAtivo4, setSelectAtivo4] = useState([]);
  const [selectAtivo5, setSelectAtivo5] = useState([]);
  const [ativo, setAtivo] = useState([]);
  const [ativo2, setAtivo2] = useState([]);
  const [ativo3, setAtivo3] = useState([]);
  const [ativo4, setAtivo4] = useState([]);
  const [ativo5, setAtivo5] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();

  //após requisição
  const [medidaReferencia, setMedidaReferencia] = useState("");
  const [ativoNomeReferencia, setAtivoNomeReferencia] = useState("");
  const [medicoesReferencia, setMedicoesReferencia] = useState([]);
  const [ativoNomeAtivo2, setAtivoNomeAtivo2] = useState("");
  const [medicoesAtivo2, setMedicoesAtivo2] = useState([]);
  const [ativoNomeAtivo3, setAtivoNomeAtivo3] = useState("");
  const [medicoesAtivo3, setMedicoesAtivo3] = useState([]);
  const [ativoNomeAtivo4, setAtivoNomeAtivo4] = useState("");
  const [medicoesAtivo4, setMedicoesAtivo4] = useState([]);
  const [ativoNomeAtivo5, setAtivoNomeAtivo5] = useState("");
  const [medicoesAtivo5, setMedicoesAtivo5] = useState([]);

  const opcoesMedidas = (inputValue) => {
    const token = localStorage.getItem("token");
    return api
      .get(`/medidas/lista-de-medidas`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        const res = response.data.medidas.map((medida) => ({
          value: medida.medida_nome,
          label: medida.medida_nome,
        }));
        return res;
      })
      .catch((err) => {});
  };

  //Listagem de Ativos
  const opcoesAtivos = (inputValue) => {
    const token = localStorage.getItem("token");
    return api
      .get(`/ativos/lista-de-ativos`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        const res = response.data.ativos.map((ativo) => ({
          value: ativo.ativo_nome,
          label: ativo.ativo_nome,
        }));
        return res;
      })
      .catch((err) => {
        console.error("Ops! ocorreu um erro: " + err);
      });
  };

  function handleMedida(medida) {
    setMedida(medida);
  }
  function handleAtivo(ativo) {
    setSelectAtivo(ativo);
    setAtivo(ativo.value);
  }
  function handleAtivo2(ativo) {
    setSelectAtivo2(ativo);
    setAtivo2(ativo.value);
  }
  function handleAtivo3(ativo) {
    setSelectAtivo3(ativo);
    setAtivo3(ativo.value);
  }
  function handleAtivo4(ativo) {
    setSelectAtivo4(ativo);
    setAtivo4(ativo.value);
  }
  function handleAtivo5(ativo) {
    setSelectAtivo5(ativo);
    setAtivo5(ativo.value);
  }
  function botaoMenos3(event) {
    event.preventDefault();
    setCount(count - 1);
    setAtivoNomeAtivo3("");
    setMedicoesAtivo3([]);
    setSelectAtivo3([]);
    setAtivo3(null);
  }
  function botaoMenos4(event) {
    event.preventDefault();
    setCount(count - 1);
    setAtivoNomeAtivo4("");
    setMedicoesAtivo4([]);
    setSelectAtivo4([]);
    setAtivo4(null);
  }
  function botaoMenos5(event) {
    event.preventDefault();
    setCount(count - 1);
    setAtivoNomeAtivo5("");
    setMedicoesAtivo5([]);
    setSelectAtivo5([]);
    setAtivo5(null);
  }

  function onSubmit(event) {
    event.preventDefault();

    const token = localStorage.getItem("token");

    api
      .post(
        `/relatorios/comparativo`,
        {
          medida,
          ativo,
          ativo2,
          ativo3,
          ativo4,
          ativo5,
          dataInicial,
          dataFinal,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setMedidaReferencia(response.data.medida_referencia);

        setAtivoNomeReferencia(response.data.referencia_ativo_nome);

        setMedicoesReferencia(response.data.medicoes_referencia);

        setAtivoNomeAtivo2(response.data.ativo2_ativo_nome);

        setMedicoesAtivo2(response.data.medicoes_ativo2);

        setAtivoNomeAtivo3(response.data.ativo3_ativo_nome);

        setMedicoesAtivo3(response.data.medicoes_ativo3);

        setAtivoNomeAtivo4(response.data.ativo4_ativo_nome);

        setMedicoesAtivo4(response.data.medicoes_ativo4);

        setAtivoNomeAtivo5(response.data.ativo5_ativo_nome);

        setMedicoesAtivo5(response.data.medicoes_ativo5);
      });
  }

  document.title =
    "Relatório Comparativo | Microméros - AS3 Engenharia Elétrica RJ";

  return (
    <Layout>
      <div className="page-content">
        <Breadcrumbs
          title="Relatórios"
          breadcrumbItem="Relatório Comparativo"
        />
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <CardTitle>Referência</CardTitle>
                </Row>
                <Row>
                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Label className=" text-dark">Parâmetro</Label>
                          <AsyncSelect
                            className="text-dark"
                            cacheOptions
                            defaultOptions
                            name="parametros-medidas"
                            value={medida}
                            onChange={handleMedida}
                            loadOptions={opcoesMedidas}
                            placeholder="Selecione o parâmetro"
                            classNamePrefix="select2-selection"
                            isSearchable
                            menuPosition="fixed"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <Label className=" text-dark">Ativo</Label>
                          <AsyncSelect
                            className="text-dark"
                            cacheOptions
                            defaultOptions
                            name="referencia-ativo"
                            value={selectAtivo}
                            onChange={handleAtivo}
                            loadOptions={opcoesAtivos}
                            placeholder="Selecione o ativo"
                            classNamePrefix="select2-selection"
                            isSearchable
                            menuPosition="fixed"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <Label className=" text-dark">Início</Label>
                          <DatePicker
                            name="startDate"
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setDataInicial(toStartDateString(date));
                            }}
                            maxDate={endDate ? endDate : new Date()}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <Label className=" text-dark">Fim</Label>
                          <div className="col-md-12">
                            <DatePicker
                              name="endDate"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              selected={endDate}
                              onChange={(date) => {
                                setEndDate(date);
                                setDataFinal(toEndDateString(date));
                              }}
                              minDate={startDate}
                              maxDate={new Date()}
                              autoComplete="off"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <CardTitle>Comparativo</CardTitle>
                    </Row>

                    <Row className="align-items-center ">
                      <Col lg="3">
                        <div className="mb-3">
                          <Label className=" text-dark">Ativo</Label>
                          <AsyncSelect
                            className="text-dark"
                            cacheOptions
                            defaultOptions
                            name="comparativo-ativo1"
                            value={selectAtivo2}
                            onChange={handleAtivo2}
                            loadOptions={opcoesAtivos}
                            placeholder="Selecione o ativo"
                            classNamePrefix="select2-selection"
                            menuPosition="fixed"
                          />
                        </div>
                      </Col>
                    </Row>

                    {count >= 2 ? (
                      <Row className="align-items-center ">
                        <Col lg="3">
                          <div className="mb-3">
                            <Label className=" text-dark">Ativo</Label>
                            <AsyncSelect
                              className="text-dark"
                              cacheOptions
                              defaultOptions
                              name="comparativo-ativo1"
                              value={selectAtivo3}
                              onChange={handleAtivo3}
                              loadOptions={opcoesAtivos}
                              placeholder="Selecione o ativo"
                              classNamePrefix="select2-selection"
                              menuPosition="fixed"
                            />
                          </div>
                        </Col>

                        {count > 2 ? null : (
                          <Col lg="1" className="mt-2">
                            <Button color="primary" onClick={botaoMenos3}>
                              <i className="fa fa-minus" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    ) : null}

                    {count >= 3 ? (
                      <Row className="align-items-center ">
                        <Col lg="3">
                          <FormGroup>
                            <Label className=" text-dark">Ativo</Label>
                            <AsyncSelect
                              className="text-dark"
                              cacheOptions
                              defaultOptions
                              name="comparativo-ativo1"
                              value={selectAtivo4}
                              onChange={handleAtivo4}
                              loadOptions={opcoesAtivos}
                              placeholder="Selecione o ativo"
                              classNamePrefix="select2-selection"
                              menuPosition="fixed"
                            />
                          </FormGroup>
                        </Col>

                        {count > 3 ? null : (
                          <Col lg="1" className="mt-2">
                            <Button color="primary" onClick={botaoMenos4}>
                              <i className="fa fa-minus" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    ) : null}

                    {count >= 4 ? (
                      <Row className="align-items-center ">
                        <Col lg="3">
                          <FormGroup>
                            <Label className=" text-dark">Ativo</Label>
                            <AsyncSelect
                              className="text-dark"
                              cacheOptions
                              defaultOptions
                              name="comparativo-ativo1"
                              value={selectAtivo5}
                              onChange={handleAtivo5}
                              loadOptions={opcoesAtivos}
                              placeholder="Selecione o ativo"
                              classNamePrefix="select2-selection"
                              menuPosition="fixed"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="1" className="mt-2">
                          <Button color="primary" onClick={botaoMenos5}>
                            <i className="fa fa-minus" />
                          </Button>
                        </Col>
                      </Row>
                    ) : null}

                    <Row className="justify-content-between mt-2 mb-2">
                      <Col lg="3" className="mb-3">
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => setCount(count + 1)}
                          disabled={count >= 4}
                        >
                          <i className="fa fa-plus" />
                        </Button>
                      </Col>

                      <Col lg="3" className="mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={
                            ativo.length < 1 ||
                            ativo2.length < 1 ||
                            startDate == null ||
                            endDate == null
                          }
                        >
                          Comparar
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                  <hr />

                  {medidaReferencia ? (
                    <>
                      <Row className="mb-5">
                        <Col sm="12">
                          <GraficoComparativo
                            medidaReferencia={medidaReferencia}
                            ativoNomeReferencia={ativoNomeReferencia}
                            medicoesReferencia={medicoesReferencia}
                            ativoNomeAtivo2={ativoNomeAtivo2}
                            medicoesAtivo2={medicoesAtivo2}
                            ativoNomeAtivo3={ativoNomeAtivo3}
                            medicoesAtivo3={medicoesAtivo3}
                            ativoNomeAtivo4={ativoNomeAtivo4}
                            medicoesAtivo4={medicoesAtivo4}
                            ativoNomeAtivo5={ativoNomeAtivo5}
                            medicoesAtivo5={medicoesAtivo5}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div className="table-responsive">
                          <Table className="table">
                            <tbody>
                              <tr>
                                <th>Parâmetro:</th>
                                <td>{medidaReferencia}</td>
                              </tr>
                              <tr>
                                <th>Período:</th>
                                <td>
                                  {new Date(startDate).toLocaleDateString(
                                    "pt-BR"
                                  )}{" "}
                                  -
                                  {new Date(endDate).toLocaleDateString(
                                    "pt-BR"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th> Referência:</th>
                                {ativoNomeReferencia ? (
                                  <>
                                    <td>{ativoNomeReferencia}</td>
                                  </>
                                ) : null}
                              </tr>
                              <tr>
                                <th> Comparativo 1: </th>
                                {ativoNomeAtivo2 ? (
                                  <>
                                    <td>{ativoNomeAtivo2}</td>
                                  </>
                                ) : null}
                              </tr>
                              <tr>
                                {ativoNomeAtivo3 ? (
                                  <>
                                    <th>Comparativo 2:</th>
                                    <td>{ativoNomeAtivo3}</td>
                                  </>
                                ) : null}
                              </tr>
                              <tr>
                                {ativoNomeAtivo4 ? (
                                  <>
                                    <th>Comparativo 3:</th>
                                    <td>{ativoNomeAtivo4}</td>
                                  </>
                                ) : null}
                              </tr>
                              <tr>
                                {ativoNomeAtivo5 ? (
                                  <>
                                    <th>Comparativo 4:</th>
                                    <td>{ativoNomeAtivo5}</td>
                                  </>
                                ) : null}
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </>
                  ) : null}
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default RelatorioComparativo;
