import React, { useState } from "react";
import api from "../../../services/api";
//redux
import PropTypes from "prop-types";
import {
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { toast } from "react-toastify";

toast.configure();
const ResolverEventoModal = (props) => {
  const { isOpen, toggle, eventoid } = props;
  //Lugar para declarar constantes e variáveis necessárias
  //Declaração das variáveis state para o formulário
  const [newLogResolucao, setnewLogResolucao] = useState({
    motivo: "Resolvido",
    comentario: null,
  });

  //botão de informações
  const [popoverInformacoes, setpopoverInformacoes] = useState(false);

  //setando função que armazena valores em comentario
  function onChange(event) {
    const { value, name } = event.target;

    setnewLogResolucao({
      ...newLogResolucao,
      [name]: value,
    });
  }

  // handleValidSubmit para enviar o formulário via requisição post para micromeros-back
  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");
    api
      .post(`/eventos/${eventoid}/resolver-evento`, newLogResolucao, {
        headers: { Authorization: token },
      })
      .then((response) => {
        toast.success("Evento resolvido com sucesso!", {
          autoClose: 3000,
        });
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err, {
          autoClose: 3000,
        });
      });
  }
  //Não declara mais nada daqui pra baixo
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      tabIndex="-1"
      toggle={toggle}
      eventoid={eventoid}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>
          Resolver evento{"  "}
          <span
            className="badge rounded-pill bg-primary font-weight-bold font-size-14 "
            id="informacoes"
            role="button"
            to="#"
            onClick={() => {
              setpopoverInformacoes(!popoverInformacoes);
            }}
          >
            i
          </span>
          <Popover
            placement="right"
            isOpen={popoverInformacoes}
            target="informacoes"
            toggle={() => {
              setpopoverInformacoes(!popoverInformacoes);
            }}
          >
            <PopoverHeader>
              <span className="font-weight-semibold">Informações</span>
            </PopoverHeader>
            <PopoverBody>
              <p className="font-weight-semibold">Motivos do evento</p>
              <p>
                <span className="font-weight-semibold">Resolvido:</span> Foi
                investigado o problema e as medidas corretivas necessárias foram
                executadas.
              </p>
              <p>
                <span className="font-weight-semibold">Alarme falso:</span> Foi
                investigado o problema e o valor medido estava incorreto.
              </p>
              <p>
                <span className="font-weight-semibold">Ignorado:</span> Foi
                decidido que a notificação do problema deve ser ignorada.
              </p>
            </PopoverBody>
          </Popover>{" "}
          &nbsp;
        </ModalHeader>
        <form className="form-horizontal" onSubmit={onSubmit}>
          <ModalBody>
            <div className="mb-3">
              <Label for="input-motivo">Informe o motivo</Label>
              <Input
                type="select"
                name="motivo"
                className="form-select"
                onChange={onChange}
              >
                <option value="Resolvido">Resolvido</option>
                <option value="Alarme falso">Alarme falso</option>
                <option value="Ignorado">Ignorado</option>
              </Input>
            </div>

            <div className="mb-3">
              <Label className=" text-dark">
                Informe um comentário (max. 500 caracteres)
              </Label>
              <Input
                name="comentario"
                type="textarea"
                rows="4"
                placeholder="Comente aqui..."
                className="form-control"
                onChange={onChange}
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="link" onClick={toggle}>
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </div>
    </Modal>
  );
};

ResolverEventoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  eventoid: PropTypes.any,
};

export default ResolverEventoModal;
