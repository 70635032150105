import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

function GraficoTotalEventosAtivos(props) {
  const { manutencao, alarmes, alertas } = props;

  const series = [manutencao, alertas, alarmes];
  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -5,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ["Manutenção", "Alertas", "Alarmes"],
    colors: ["#79829c", "#f1b44c", "#f46a6a"],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="380"
    />
  );
}

GraficoTotalEventosAtivos.propTypes = {
  manutencao: PropTypes.any,
  alarmes: PropTypes.any,
  alertas: PropTypes.any,
};

export default GraficoTotalEventosAtivos;
