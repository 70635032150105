import PropTypes from "prop-types";
import { CardTitle, Col, Media, Row } from "reactstrap";
function Tab2(props) {
  return (
    <>
      <Row className="mt-4">
        <Col sm="12" lg="6" xl="6">
          <CardTitle className="mb-3 text-center">Detalhes do ativo</CardTitle>
          <ul className="row p-0">
            <li className="list-inline-item mb-2">
              <Media>
                <strong>ID do Ativo</strong>
                <Media body>
                  <span className="float-end">{props.ativo.ativo_id}</span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Nome</strong>
                <Media body>
                  <span className="float-end">{props.ativo.ativo_nome}</span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Tipo</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.ativo_tipo_nome}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Nome da Planta</strong>
                <Media body>
                  <span className="float-end">{props.ativo.planta_nome}</span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Nome da Organização</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.organizacao_nome}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Número de Série</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.ativo_serial_number}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Descrição</strong>
                <Media body>
                  <div className="float-end">
                    {props.ativo.ativo_descricao
                      ? props.ativo.ativo_descricao
                      : "Sem descrição"}
                  </div>
                </Media>
              </Media>
            </li>
          </ul>
        </Col>
        <Col sm="12" lg="6" xl="6">
          <CardTitle className="mb-3 text-center">Detalhes nominais</CardTitle>
          <ul className="row ps-0">
            <li className="list-inline-item mb-2">
              <Media>
                <strong>Velocidade</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.velocidade_nominal
                      ? props.ativo.velocidade_nominal
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Tensão</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.tensao_nominal
                      ? props.ativo.tensao_nominal
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Corrente</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.corrente_nominal
                      ? props.ativo.corrente_nominal
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Potência</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.potencia_nominal
                      ? props.ativo.potencia_nominal
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>

            <li className="list-inline-item mb-2">
              <Media>
                <strong>Frequência de Linha</strong>
                <Media body>
                  <span className="float-end">
                    {props.ativo.frequencia_nominal
                      ? props.ativo.frequencia_nominal
                      : "Não informado"}
                  </span>
                </Media>
              </Media>
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
}
Tab2.propTypes = {
  ativo: PropTypes.object,
};

export default Tab2;
