import axios from "axios";

const api = axios.create({
  baseURL: "https://api.as3engenharia.com.br",
});

export default api;

// "https://api.as3engenharia.com.br"
// "http://api-beta.as3engenharia.com.br"
// "http://micromeros-back.test"
//TODO: INSERIR VARIÁVEIS DE AMBIENTE PARA HOMEPAGE EM PACKAGE.JSON E
//URL EM API.JS
