import React, { useEffect, useState } from "react";
import Layout from "../../../components/VerticalLayout";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Table,
} from "reactstrap";
import { useParams } from "react-router-dom";
import api from "../../../services/api";
import { Stack } from "react-bootstrap";
import { map } from "lodash";
import ReactTooltip from "react-tooltip";
import EditarGrupoAtivosModal from "./modais/EditarGrupoAtivosModal";
import TransferirAtivoModal from "./modais/TransferirAtivoModal";
import AdicionarAtivosGrupoAtivosModal from "./modais/AdicionarAtivosGrupoAtivosModal";
import AdicionarGrupoUsuariosModal from "./modais/AdicionarGrupoUsuariosModal";
import DesvincularGrupoUsuariosModal from "./modais/DesvincularGrupoUsuariosModal";
import { toast } from "react-toastify";

toast.configure();

function VisualizarGrupoDeAtivos() {
  let params = useParams();
  const [grupoNome, setGrupoNome] = useState();
  const [planta, setPlanta] = useState();
  const [frequencia, setFrequencia] = useState();
  const [ativos, setAtivos] = useState([]);
  const [grupoUsuarios, setGrupoUsuarios] = useState([]);
  const [isAdmin, setIsAdmin] = useState();
  const [isModerator, setIsModerator] = useState();
  const [editarGrupoAtivosModal, setEditarGrupoAtivosModal] = useState(false);
  const [transferirAtivoModal, setTransferirAtivoModal] = useState(false);
  const [adicionarAtivosGrupoAtivosModal, setAdicionarAtivosGrupoAtivosModal] =
    useState(false);
  const [adicionarGrupoUsuariosModal, setAdicionarGrupoUsuariosModal] =
    useState(false);
  const [desvincularGrupoAtivosModal, setDesvincularGrupoAtivosModal] =
    useState(false);
  const [ativoId, setAtivoId] = useState();
  const [ativoPlantaId, setAtivoPlantaId] = useState();
  const [grupoUsuariosId, setGrupoUsuariosId] = useState();

  function toggleEditarGrupoAtivosModal() {
    setEditarGrupoAtivosModal(!editarGrupoAtivosModal);
  }

  function toggleTransferirAtivoModal() {
    setTransferirAtivoModal(!transferirAtivoModal);
  }

  function toggleAdicionarAtivosGrupoAtivosModal() {
    setAdicionarAtivosGrupoAtivosModal(!adicionarAtivosGrupoAtivosModal);
  }

  function toggleAdicionarGrupoUsuariosModal() {
    setAdicionarGrupoUsuariosModal(!adicionarGrupoUsuariosModal);
  }

  function toggleDesvincularGrupoAtivosModal() {
    setDesvincularGrupoAtivosModal(!desvincularGrupoAtivosModal);
  }

  function handleFrequencia(frequencia) {
    frequencia === null ? setFrequencia(null) : setFrequencia(frequencia / 60);
  }

  function handleIsModerator(grupos) {
    for (let i = 0; i < grupos.length; i++) {
      if (grupos[i].is_moderator === 1) {
        setIsModerator(true);
        return;
      }
    }
    setIsModerator(false);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    api
      .get(`gerenciamento/grupos-de-ativos/${params.grupoAtivosId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setGrupoNome(response.data.grupo_ativos.grupo_ativos_nome);
        setPlanta(response.data.grupo_ativos.planta);
        handleFrequencia(response.data.grupo_ativos.frequencia);
        setAtivos(response.data.grupo_ativos.ativos);
        setGrupoUsuarios(response.data.grupo_ativos.grupo_usuarios);
        setIsAdmin(response.data.grupo_ativos.is_admin);
        handleIsModerator(grupoUsuarios);
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          toast.error(error.response.data.mensagem_erro, {
            autoClose: 3000,
          });
        }
      });
  }, [
    grupoNome,
    editarGrupoAtivosModal,
    transferirAtivoModal,
    adicionarAtivosGrupoAtivosModal,
    adicionarGrupoUsuariosModal,
    desvincularGrupoAtivosModal,
  ]);

  document.title =
    "Visualizar Grupo de ativos | Microméros - AS3 Engenharia Elétrica RJ";
  return (
    <Layout>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Grupos de ativos"
            breadcrumbItem="Visualizar Grupo de ativos"
            link="/gerenciamento/grupos-de-ativos"
          />

          <Row>
            <Col xl="6" lg="6" sm="12" md="6">
              <Card>
                <CardBody>
                  <Stack direction="vertical" gap={1}>
                    <Stack direction="horizontal" gap={3}>
                      <CardTitle>{grupoNome}</CardTitle>{" "}
                      {isAdmin === 1 || isModerator ? (
                        <>
                          <Button
                            type="button"
                            className="btn-rounded btn-sm mb-2"
                            color="light"
                            data-tip
                            data-for="tooltip-editar-nome-grupo-usuarios"
                            onClick={() => {
                              toggleEditarGrupoAtivosModal();
                            }}
                          >
                            <i className="bx bx-edit font-size-12" />
                          </Button>
                          <ReactTooltip
                            id="tooltip-editar-nome-grupo-usuarios"
                            place="top"
                            effect="solid"
                          >
                            Editar
                          </ReactTooltip>
                        </>
                      ) : null}
                    </Stack>
                    <CardSubtitle>{planta}</CardSubtitle>
                    <CardText>
                      Frequência de notificações:{" "}
                      {frequencia === null ? (
                        <>Desativada</>
                      ) : frequencia === 1 ? (
                        <> {frequencia} hora</>
                      ) : (
                        <> {frequencia} horas</>
                      )}
                    </CardText>
                  </Stack>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {editarGrupoAtivosModal ? (
            <EditarGrupoAtivosModal
            toggle={toggleEditarGrupoAtivosModal}
            isOpen={editarGrupoAtivosModal}
            grupoativoid={params.grupoAtivosId}
            moderator={isModerator}
            />
            ): null}
          <Row>
            <Col xl="6" lg="6" sm="12" md="6">
              <Card>
                <CardBody>
                  <CardTitle>
                    <Stack direction="horizontal" gap={3}>
                      <div>Ativos</div>
                      {isAdmin === 1 ? (
                        <>
                          <div className="ms-auto">
                            <Button
                              color="primary"
                              onClick={toggleAdicionarAtivosGrupoAtivosModal}
                            >
                              Adicionar ativos
                            </Button>
                          </div>
                        </>
                      ) : null}
                    </Stack>
                  </CardTitle>
                  <Row>
                    {ativos.length > 0 ? (
                      <Col lg="12" className="table-responsive mb-0">
                        <Table>
                          <thead>
                            <tr>
                              <th>Ativo</th>
                              <th>Sensor</th>
                              <th>Fabricante do sensor</th>
                              {isAdmin === 1 ? <th>Ações</th> : null}
                            </tr>
                          </thead>
                          {map(ativos, (ativo, key) => (
                            <tbody key={ativo.ativo_id}>
                              <tr>
                                <td>{ativo.ativo_nome}</td>
                                <td>{ativo.sensor_nome}</td>
                                <td>{ativo.fabricante_nome}</td>
                                <td>
                                  {isAdmin === 1 ? (
                                    <>
                                      <Button
                                        type="button"
                                        className="btn-rounded btn-sm mb-2"
                                        color="light"
                                        data-tip
                                        data-for="tooltip-transferir-ativo"
                                        onClick={() => {
                                          setAtivoId(ativo.ativo_id);
                                          setAtivoPlantaId(
                                            ativo.ativo_planta_id
                                          );
                                          toggleTransferirAtivoModal();
                                        }}
                                      >
                                        <i className="bx bx-transfer font-size-12" />
                                      </Button>
                                    </>
                                  ) : null}
                                  <ReactTooltip
                                    id="tooltip-transferir-ativo"
                                    place="top"
                                    effect="solid"
                                  >
                                    Transferir para outro grupo
                                  </ReactTooltip>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      </Col>
                    ) : (
                      <>
                        <Row>
                          <Col lg="12">
                            <p className="text-center mt-2">
                              {" "}
                              <span className="badge rounded-pill bg-primary font-weight-bold font-size-12 me-2">
                                i
                              </span>
                              Não há ativos para listar.
                            </p>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {transferirAtivoModal ? (
              <TransferirAtivoModal
              toggle={toggleTransferirAtivoModal}
              isOpen={transferirAtivoModal}
              ativoid={ativoId}
              plantaid={ativoPlantaId}
              />
              ): null}
              {adicionarAtivosGrupoAtivosModal ? (
                <AdicionarAtivosGrupoAtivosModal
                toggle={toggleAdicionarAtivosGrupoAtivosModal}
                isOpen={adicionarAtivosGrupoAtivosModal}
                grupoativoid={params.grupoAtivosId}
                />
                ): null}

            <Col xl="6" lg="6" sm="12" md="6">
              <Card>
                <CardBody>
                  <CardTitle>
                    {" "}
                    <Stack direction="horizontal" gap={3}>
                      <div>Grupos de usuários</div>
                      <div className="ms-auto">
                        {isAdmin === 1 ? (
                          <>
                            <Button
                              color="primary"
                              onClick={toggleAdicionarGrupoUsuariosModal}
                            >
                              Adicionar grupo de usuario
                            </Button>
                          </>
                        ) : null}
                      </div>
                    </Stack>
                  </CardTitle>
                  <Row>
                    {grupoUsuarios.length > 0 ? (
                      <Col lg="12" className="table-responsive mb-0">
                        <Table>
                          <thead>
                            <tr>
                              <th>Grupo de usuários</th>
                              {isAdmin === 1 ? <th>Ações</th> : null}
                            </tr>
                          </thead>
                          {map(grupoUsuarios, (grupoUsuario, key) => (
                            <tbody key={grupoUsuario.grupo_usuarios_id}>
                              <tr>
                                <td>{grupoUsuario.grupo_usuarios_nome}</td>
                                <td>
                                  {isAdmin === 1 ? (
                                    <>
                                      <Button
                                        type="button"
                                        className="btn-rounded btn-sm mb-2"
                                        color="light"
                                        data-tip
                                        data-for="tooltip-excluir-grupo"
                                        onClick={() => {
                                          setGrupoUsuariosId(
                                            grupoUsuario.grupo_usuarios_id
                                          );
                                          toggleDesvincularGrupoAtivosModal();
                                        }}
                                      >
                                        <i className="bx bx-x font-size-12" />
                                      </Button>
                                    </>
                                  ) : null}
                                  <ReactTooltip
                                    id="tooltip-excluir-grupo"
                                    place="top"
                                    effect="solid"
                                  >
                                    Excluir grupo
                                  </ReactTooltip>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      </Col>
                    ) : (
                      <>
                        <Row>
                          <Col lg="12">
                            <p className="text-center mt-2">
                              {" "}
                              <span className="badge rounded-pill bg-primary font-weight-bold font-size-12 me-2">
                                i
                              </span>
                              Não há grupos de usuários para listar.
                            </p>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <AdicionarGrupoUsuariosModal
              toggle={toggleAdicionarGrupoUsuariosModal}
              isOpen={adicionarGrupoUsuariosModal}
              grupoativoid={params.grupoAtivosId}
            />
            <DesvincularGrupoUsuariosModal
              toggle={toggleDesvincularGrupoAtivosModal}
              isOpen={desvincularGrupoAtivosModal}
              grupousuarioid={grupoUsuariosId}
            />
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default VisualizarGrupoDeAtivos;
