import React, { useState } from "react";
import PropTypes from "prop-types";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

toast.configure();

function AdicionarUsuarioGrupoUsuariosModal(props) {
  const { isOpen, toggle, grupousuariosid } = props;

  const [erro, setErro] = useState(null);
  const [emailNovoUsuario, setEmailNovoUsuario] = useState(null);
  const [isModerator, setIsModerator] = useState(null);

  //Listagem de emails
  const opcoesEmailNovoUsuario = (inputValue) => {
    const token = localStorage.getItem("token");
    return api
      .get(`/gerenciamento/grupos-de-usuarios/adicionar-usuarios/emails`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        const res = response.data.emails.map((email) => ({
          value: email.email,
          label: email.email,
        }));
        return res;
      })
      .catch((err) => {
        console.error("Ops! ocorreu um erroo: " + err);
      });
  };

  function handleEmailNovoUsuario(email) {
    setEmailNovoUsuario(email.value);
  }

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");

    api
      .post(
        `/gerenciamento/grupos-de-usuarios/${grupousuariosid}/usuarios`,
        {
          emailNovoUsuario,
          isModerator,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          setErro(error.response.data.mensagem_erro);
        }
      });
  }
  return (
    <>
      <Modal
        id="modal-adicionar-usuario-grupo-usuarios"
        isOpen={isOpen}
        autoFocus={true}
        className="modal-dialog-centered"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader>Adicionar usuário ao Grupo de usuários</ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <div className=" col-md-12 mb-3">
                <Label className=" text-dark">E-mail do novo membro</Label>
                <AsyncSelect
                  className="text-dark"
                  cacheOptions
                  defaultOptions
                  name="emailNovoUsuario"
                  onChange={handleEmailNovoUsuario}
                  loadOptions={opcoesEmailNovoUsuario}
                  placeholder="Selecione o usuário"
                  classNamePrefix="select2-selection"
                />
              </div>
              <Label className=" text-dark">Permissão</Label>

              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="membro"
                  id="radio-input-novo-membro"
                  checked={isModerator === 0}
                  onClick={() => setIsModerator(0)}
                />
                <Link
                  to="#"
                  onClick={() => setIsModerator(0)}
                  className="text-dark"
                >
                  <label
                    className="form-check-label"
                    htmlFor="radio-input-novo-membro"
                  >
                    Membro
                  </label>
                </Link>
              </div>

              <div className="form-check ms-auto">
                <input
                  className="form-check-input"
                  type="radio"
                  name="moderador"
                  id="radio-input-novo-moderador"
                  checked={isModerator === 1}
                  onClick={() => setIsModerator(1)}
                />
                <Link
                  to="#"
                  onClick={() => setIsModerator(1)}
                  className="text-dark"
                >
                  <label
                    className="form-check-label"
                    htmlFor="radio-input-novo-moderador"
                  >
                    Moderador
                  </label>
                </Link>
              </div>
              <span className="text-danger">{erro ? erro : null}</span>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="link" onClick={toggle}>
                Cancelar
              </Button>
              <Button color="primary" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </form>
        </div>
      </Modal>
    </>
  );
}

AdicionarUsuarioGrupoUsuariosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  grupousuariosid: PropTypes.any,
};

export default AdicionarUsuarioGrupoUsuariosModal;
