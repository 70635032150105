import React, { useEffect, useState } from "react";
import CriarNovoGrupoUsuariosModal from "./modais/CriarNovoGrupoUsuariosModal";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import api from "../../../services/api";
import { Button, Col, Row, Table } from "reactstrap";
import ExcluirGrupoUsuariosModal from "./modais/ExcluirGrupoUsuariosModal";
import { map } from "lodash";

function TabelaGruposDeUsuarios() {
  const [grupousuariosid, setGrupoUsuariosId] = useState(null);
  const [grupos, setGrupos] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  //paginação
  const [activePage, setActivePage] = useState(1);
  const [gruposTotal, setGruposTotal] = useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  //Modais
  const [abrirCriarGrupoUsuariosModal, setAbrirCriarGrupoUsuariosModal] =
    useState(false);

  const [abrirExcluirGrupoUsuariosModal, setAbrirExcluirGrupoUsuariosModal] =
    useState(false);

  function toggleCriarGrupoUsuariosModal() {
    setAbrirCriarGrupoUsuariosModal(!abrirCriarGrupoUsuariosModal);
  }

  function toggleExcluirGrupoUsuariosModal(grupousuariosid) {
    setAbrirExcluirGrupoUsuariosModal(!abrirExcluirGrupoUsuariosModal);
    setGrupoUsuariosId(grupousuariosid);
  }

  useEffect(() => {
    //conexão com a API
    const token = localStorage.getItem("token");
    try {
      //listagem dos grupos de usuarios
      api
        .get(`/gerenciamento/grupos-de-usuarios?page=${activePage}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setGrupos(response.data.grupos_usuarios.data);
          setGruposTotal(response.data.grupos_usuarios.total);
          setIsAdmin(response.data.is_admin);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      alert(e.message);
    }
  }, [activePage]);

  return (
    <>
      {" "}
      <Row className="mb-4">
        {isAdmin === 1 || isAdmin === true ? (
          <Col lg={12} sm={12}>
            <Button color="primary" onClick={toggleCriarGrupoUsuariosModal}>
              Criar grupo de usuários
            </Button>

            <CriarNovoGrupoUsuariosModal
              isOpen={abrirCriarGrupoUsuariosModal}
              toggle={toggleCriarGrupoUsuariosModal}
              setGrupos={setGrupos}
            />
            <ExcluirGrupoUsuariosModal
              isOpen={abrirExcluirGrupoUsuariosModal}
              toggle={toggleExcluirGrupoUsuariosModal}
              grupousuariosid={grupousuariosid}
              setGrupos={setGrupos}
            />
          </Col>
        ) : null}
      </Row>
      <Row>
        <Table className="table mb-0">
          <thead>
            <tr>
              <th>Nome do grupo</th>
              <th>Planta</th>
              <th>Ações</th>
            </tr>
          </thead>
          {map(grupos, (grupo, key) => (
            <tbody key={grupo.grupo_usuarios_id}>
              <tr>
                <td>{grupo.grupo_usuarios_nome}</td>
                <td>{grupo.planta}</td>
                <td>
                  <>
                    <span className="d-flex gap-3">
                      {/*Editar Grupo de usuários*/}
                      <Link
                        to={
                          "/gerenciamento/grupos-de-usuarios/" +
                          grupo.grupo_usuarios_id
                        }
                      >
                        <Button
                          type="button"
                          className="btn-rounded"
                          color="light"
                          data-tip
                          data-for="tooltip-editar-grupo-de-usuarios"
                        >
                          <i className="mdi mdi-eye-outline font-size-14" />
                        </Button>
                        <ReactTooltip
                          id="tooltip-editar-grupo-de-usuarios"
                          place="top"
                          effect="solid"
                        >
                          Visualizar
                        </ReactTooltip>
                      </Link>
                      {grupo.is_admin === 1 && !(grupo.is_default === 1) ? (
                        <>
                          <Button
                            type="button"
                            className="btn-rounded"
                            color="light"
                            data-tip
                            data-for="tooltip-excluir-grupo-de-usuarios"
                            onClick={() => {
                              toggleExcluirGrupoUsuariosModal(
                                grupo.grupo_usuarios_id
                              );
                            }}
                          >
                            <i className="bx bx-x font-size-14" />
                          </Button>
                          <ReactTooltip
                            id="tooltip-excluir-grupo-de-usuarios"
                            place="top"
                            effect="solid"
                          >
                            Excluir
                          </ReactTooltip>
                        </>
                      ) : null}
                    </span>
                  </>
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </Row>
      {/* Botões de paginação */}
      <Row>
        <Col
          lg="12"
          className="pagination pagination-rounded justify-content-center mb-2 mt-3"
        >
          <Pagination
            activePage={activePage}
            totalItemsCount={gruposTotal}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
            itemsCountPerPage={5}
          />
        </Col>
      </Row>
    </>
  );
}

export default TabelaGruposDeUsuarios;
