import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Stack } from "react-bootstrap";

function CriarNovoGrupoAtivosModal(props) {
  const { isOpen, toggle } = props;

  const [planta, setPlanta] = useState(0);
  const [novoGrupoNome, setNovoGrupoNome] = useState(0);
  const [frequencia_notificacao, setFrequencia_notificacao] = useState(null);
  const [notificaEmail, setNotificaEmail] = useState(false);
  const [notificaWhatsApp, setNotificaWhatsApp] = useState(false);
  const [notificaIBM, setNotificaIBM] = useState(false);
  const [apiIbm, setApiIbm] = useState(false);
  const [apiWhatsapp, setApiWhatsapp] = useState(false);

  function handleNotificaEmail() {
    setNotificaEmail(!notificaEmail);
  }

  function handleNotificaWhatsApp() {
    setNotificaWhatsApp(!notificaWhatsApp);
  }

  function handleNotificaIBM() {
    setNotificaIBM(!notificaIBM);
  }

  function handlePlanta(planta) {
    setPlanta(planta.label);
    handleAPI(planta.value);
  }

  function limparCampos() {
    setPlanta(0);
    setNotificaIBM(false);
    setNotificaWhatsApp(false);
    setNotificaEmail(false);
    setNovoGrupoNome(0);
    setFrequencia_notificacao(null);
    setApiIbm(false);
    setApiWhatsapp(false);
  }

  //Listagem de Plantas
  const opcoesPlanta = (inputValue) => {
    const token = localStorage.getItem("token");
    return api
      .get(`/plantas/lista-de-plantas`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        const res = response.data.plantas.map((planta) => ({
          value: planta.planta_id,
          label: planta.planta_nome,
        }));
        return res;
      })
      .catch((error) => {
        console.error("Ops! ocorreu um erro: " + error);
      });
  };

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");
    api
      .post(
        `/gerenciamento/grupos-de-ativos`,
        {
          novoGrupoNome,
          planta,
          frequencia_notificacao,
          notificaEmail,
          notificaWhatsApp,
          notificaIBM,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        limparCampos();
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          toast.error(error.response.data.mensagem_erro, {
            autoClose: 3000,
          });
        }
      });
  }

  function handleAPI(plantaId) {
    const token = localStorage.getItem("token");
    setNotificaWhatsApp(false);
    setNotificaIBM(false);
    setNotificaEmail(false);
    api
      .get(`/plantas/${plantaId}/api`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setApiIbm(response.data.ibm);
        setApiWhatsapp(response.data.whatsapp);
      })
      .catch((error) => {
        console.error("Ops! ocorreu um erro: " + error);
      });
  }

  return (
    <>
      <Modal
        id="modal-criar-grupo-ativos"
        isOpen={isOpen}
        autoFocus={true}
        className="modal-dialog-centered"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader>Criar Grupo de ativos</ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <div className=" col-md-12 mb-3">
                <Label className=" text-dark">Nome do grupo</Label>
                <Input
                  name="nome"
                  type="text"
                  className="form-control"
                  onChange={(event) => setNovoGrupoNome(event.target.value)}
                />
              </div>

              <div className="col-md-12 mb-3">
                <Label className=" text-dark">Planta</Label>
                <AsyncSelect
                  className="text-dark"
                  cacheOptions
                  defaultOptions
                  name="planta"
                  onChange={handlePlanta}
                  loadOptions={opcoesPlanta}
                  placeholder="Selecione a planta"
                  classNamePrefix="select2-selection"
                />
              </div>

              <div>
                <Label className=" text-dark">Notificações</Label>
              </div>
              <Stack direction="horizontal" gap={5}>
                <Col className="ms-1">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={notificaEmail}
                      id="notificacaoEmail"
                      onChange={handleNotificaEmail}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="notificacaoEmail"
                    >
                      E-mail
                    </label>
                  </div>
                </Col>
                {apiWhatsapp ? (
                  <Col>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={notificaWhatsApp}
                        id="notificacaoWhatsapp"
                        onChange={handleNotificaWhatsApp}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="notificacaoWhatsapp"
                      >
                        WhatsApp
                      </label>
                    </div>
                  </Col>
                ) : null}
                {apiIbm ? (
                  <Col>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={notificaIBM}
                        id="notificacaoIBM"
                        onChange={handleNotificaIBM}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="notificacaoIBM"
                      >
                        IBM Maximo
                      </label>
                    </div>
                  </Col>
                ) : null}
              </Stack>
              {notificaIBM !== false ||
              notificaWhatsApp !== false ||
              notificaEmail !== false ? (
                <>
                  <div className=" col-md-12 mt-3">
                    <Label className=" text-dark">
                      Frequência de notificações (horas)
                    </Label>
                    <Col lg="4" sm="4">
                      <Input
                        name="frequencia_notificacao"
                        type="number"
                        min="1"
                        placeholder="0"
                        className="form-control"
                        onChange={(event) =>
                          setFrequencia_notificacao(event.target.value)
                        }
                      />
                    </Col>
                  </div>
                </>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="link"
                onClick={() => {
                  limparCampos();
                  toggle();
                }}
              >
                Cancelar
              </Button>
              <Button color="primary" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </form>
        </div>
      </Modal>
    </>
  );
}

CriarNovoGrupoAtivosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CriarNovoGrupoAtivosModal;
