import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Col,
  Container,
  Row,
  Table,
  CardTitle,
  Collapse,
  FormGroup,
  Label,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import classnames from "classnames";
//MODAL
import InserirLogDeTrabalhoModal from "./InserirLogDeTrabalhoModal";
import ResolverEventoModal from "./ResolverEventoModal";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { map } from "lodash";
import moment from "moment";
//listagem de opcoes de Tipo
const opcoesTipo = [
  {
    options: [
      { label: "Alarme", value: "Alarme" },
      { label: "Alerta", value: "Alerta" },
      { label: "Manutenção", value: "Manutenção" },
    ],
  },
];

//Listagem de opções Status
const opcoesStatus = [
  {
    options: [
      { label: "Aberto", value: "Aberto" },
      { label: "Fechado", value: "Fechado" },
    ],
  },
];

function TabelaEventos() {
  //DECLARAÇÃO DE STATE DOS DADOS DA TABELA E O USEEFFECT
  const [tabelaEventosDados, settabelaEventosDados] = useState([]);
  //DECLARAÇÃO DOS MODAIS DA TABELA AÇÕES
  //InicialStates/Sets
  const [eventoid, seteventoid] = useState(null);
  const [modalLogDeTrabalho, setmodalLogDeTrabalho] = useState(false);
  const [modalResolverEvento, setmodalResolverEvento] = useState(false);
  //paginação
  const [activePage, setActivePage] = useState(1);
  const [eventosTotal, setEventosTotal] = useState(0);

  const [abrefiltro, setAbreFiltro] = useState(true);

  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss")
  );
  const [dataFinal, setDataFinal] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [tipo, setTipo] = useState();
  const [status, setStatus] = useState([{ label: "Aberto", value: "Aberto" }]);
  const [planta, setPlanta] = useState();
  const [grupoAtivos, setGrupoAtivos] = useState();
  const [ativo, setAtivo] = useState([]);
  const [sensor, setSensor] = useState();
  const [gatilho, setGatilho] = useState();

  //Listagem de Plantas
  const opcoesPlanta = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/plantas/lista-de-plantas`, {
        headers: { Authorization: token },
      });
      const res = response.data.plantas.map((planta) => ({
        value: planta.planta_nome,
        label: planta.planta_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Listagem de Grupos
  const opcoesGrupoDeAtivos = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/grupos-de-ativos`, {
        headers: { Authorization: token },
      });
      const res = response.data.grupos_ativos.map((grupo_ativos) => ({
        value: grupo_ativos.grupo_nome,
        label: grupo_ativos.grupo_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Listagem de Ativos
  const opcoesAtivos = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/ativos/lista-de-ativos`, {
        headers: { Authorization: token },
      });
      const res = response.data.ativos.map((ativo) => ({
        value: ativo.ativo_nome,
        label: ativo.ativo_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Listagem de Sensores
  const opcoesSensores = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/sensores/lista-de-sensores`, {
        headers: { Authorization: token },
      });
      const res = response.data.sensores.map((sensor) => ({
        value: sensor.sensor_nome,
        label: sensor.sensor_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  //Listagem de Gatilhos
  const opcoesGatilhos = async (inputValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/gatilhos/lista-de-gatilhos`, {
        headers: { Authorization: token },
      });
      const res = response.data.gatilhos.map((gatilho) => ({
        value: gatilho.gatilho_nome,
        label: gatilho.gatilho_nome,
      }));
      return res;
    } catch (err) {
      console.error("Ops! ocorreu um erro: " + err);
    }
  };

  function handleTipo(tipo) {
    setTipo(tipo);
  }

  function handleStatus(status) {
    setStatus(status);
  }

  function handlePlanta(planta) {
    setPlanta(planta);
  }

  function handleGrupoAtivos(grupoAtivos) {
    setGrupoAtivos(grupoAtivos);
  }

  function handleAtivo(ativo) {
    setAtivo(ativo);
  }

  function handleSensor(sensor) {
    setSensor(sensor);
  }

  function handleGatilho(gatilho) {
    setGatilho(gatilho);
  }

  const toggleFiltro = () => {
    setAbreFiltro(!abrefiltro);
  };

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }
  //Funções pra setar valor do toggle
  function toggleLogDeTrabalho(eventoID) {
    setmodalLogDeTrabalho(!modalLogDeTrabalho);
    seteventoid(eventoID);
  }

  function toggleResolverEvento(eventoID) {
    setmodalResolverEvento(!modalResolverEvento);
    seteventoid(eventoID);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");
    api
      .post(
        `/eventos?page=${activePage}`,
        {
          dataInicial,
          dataFinal,
          tipo,
          status,
          ativo,
          sensor,
          planta,
          grupoAtivos,
          gatilho,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        settabelaEventosDados(response.data.eventos.data);
        setEventosTotal(response.data.eventos.total);
      })
      .catch((err) => {
        console.error("Ops! ocorreu um erro: " + err);
      });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    api
      .post(
        `/eventos?page=${activePage}`,
        {
          dataInicial,
          dataFinal,
          tipo,
          status,
          ativo,
          sensor,
          planta,
          gatilho,
          grupoAtivos,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        settabelaEventosDados(response.data.eventos.data);
        setEventosTotal(response.data.eventos.total);
      })
      .catch((err) => {
        console.error("Ops! ocorreu um erro: " + err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  function formataData(data) {
    let dataISO = moment.utc(data).format();
    let dataConvertida = new Date(dataISO).toLocaleString("pt-BR", {
      timezone: "America/Sao_Paulo",
    });
    return dataConvertida;
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !abrefiltro,
                    })}
                    type="button"
                    onClick={toggleFiltro}
                    style={{ cursor: "pointer" }}
                  >
                    <CardTitle className="h4">
                      Filtros{" "}
                      <span className="badge rounded-pill bg-primary">
                        Contém filtros
                      </span>
                    </CardTitle>
                  </button>
                </h2>

                <Collapse isOpen={abrefiltro} className="accordion-collapse">
                  <div className="accordion-body">
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="3">
                          <FormGroup className="mb-4">
                            <Label className=" text-dark">Data de início</Label>
                            <div className="col-md-12">
                              <input
                                name="dataInicial"
                                className="form-control"
                                type="datetime-local"
                                defaultValue={moment()
                                  .subtract(7, "days")
                                  .format("YYYY-MM-DDTHH:mm")}
                                max={moment().format("YYYY-MM-DDTHH:mm")}
                                id="Data-de-inicio-datetime-local-input"
                                onChange={(event) =>
                                  setDataInicial(
                                    moment(event.target.value).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  )
                                }
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup className="mb-4">
                            <Label className=" text-dark">
                              Data de término
                            </Label>
                            <div className="col-md-12">
                              <input
                                name="dataFinal"
                                className="form-control"
                                type="datetime-local"
                                defaultValue={moment().format(
                                  "YYYY-MM-DDTHH:mm"
                                )}
                                max={moment().format("YYYY-MM-DDTHH:mm")}
                                id="Data-de-termino-datetime-local-input"
                                onChange={(event) =>
                                  setDataFinal(
                                    moment(event.target.value).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  )
                                }
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup className="mb-4">
                            <Label className=" text-dark">Tipo</Label>

                            <Select
                              className="text-dark"
                              onChange={handleTipo}
                              options={opcoesTipo}
                              id="selectTipo"
                              name="tipo"
                              classNamePrefix="select2-selection"
                              placeholder="Escolha o tipo"
                              isMulti
                              autoFocus
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup className="mb-4">
                            <Label className=" text-dark">Status</Label>
                            <Select
                              className="text-dark"
                              name="status"
                              onChange={handleStatus}
                              options={opcoesStatus}
                              placeholder="Selecione o status"
                              value={status}
                              classNamePrefix="select2-selection"
                              isMulti
                              autoFocus
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* Exibe Caixa de seleção Nome da Planta */}
                      <Row>
                        <Col lg="6">
                          <FormGroup className="mb-4">
                            <Label className=" text-dark">Planta</Label>
                            <div className="col-md-12">
                              <AsyncSelect
                                className="text-dark"
                                cacheOptions
                                defaultOptions
                                name="planta"
                                onChange={handlePlanta}
                                loadOptions={opcoesPlanta}
                                placeholder="Selecione a planta"
                                classNamePrefix="select2-selection"
                                value={planta}
                                isMulti
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup className="mb-4">
                            <Label className=" text-dark">
                              Grupo de ativos
                            </Label>
                            <AsyncSelect
                              className="text-dark"
                              isMulti
                              cacheOptions
                              defaultOptions
                              name="grupoAtivos"
                              value={grupoAtivos}
                              onChange={handleGrupoAtivos}
                              loadOptions={opcoesGrupoDeAtivos}
                              placeholder="Selecione o grupo de ativos"
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup className="mb-4">
                            <Label className=" text-dark">Nome do ativo</Label>
                            <AsyncSelect
                              className="text-dark"
                              isMulti
                              cacheOptions
                              defaultOptions
                              name="ativo"
                              value={ativo}
                              onChange={handleAtivo}
                              loadOptions={opcoesAtivos}
                              placeholder="Selecione o ativo"
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="mb-4">
                            <Label className=" text-dark">Nome do sensor</Label>
                            <div className="col-md-12">
                              <AsyncSelect
                                className="text-dark"
                                cacheOptions
                                defaultOptions
                                name="sensor"
                                onChange={handleSensor}
                                loadOptions={opcoesSensores}
                                placeholder="Selecione o sensor"
                                classNamePrefix="select2-selection"
                                isMulti
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup className="mb-4">
                            <Label className="text-dark">Gatilho</Label>

                            <AsyncSelect
                              className="text-dark"
                              cacheOptions
                              defaultOptions
                              name="gatilho"
                              onChange={handleGatilho}
                              loadOptions={opcoesGatilhos}
                              placeholder="Selecione o gatilho"
                              classNamePrefix="select2-selection"
                              isMulti
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="text-center">
                          <Button color="link" type="reset">
                            Limpar filtros
                          </Button>

                          <Button color="primary" type="submit">
                            Filtrar
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Collapse>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="table-responsive mb-0">
            <Table className="table mb-0 text-center">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Data</th>
                  <th>Nome do gatilho</th>
                  <th>Medida</th>
                  <th>Planta</th>
                  <th>Grupo de ativos</th>
                  <th>Ativo</th>
                  <th>Sensor</th>
                  <th>Data de resolução</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              {map(tabelaEventosDados, (evento, key) => (
                <tbody key={key}>
                  <tr>
                    <th scope="row">
                      <Badge
                        className={
                          "font-size-12 badge rounded-pill bg-" +
                          evento.evento_severidade
                        }
                        pill
                      >
                        {" "}
                        {evento.evento_severidade}
                      </Badge>
                    </th>
                    <td>{formataData(evento.data_deteccao)}</td>
                    <td>{evento.gatilho_nome}</td>
                    <td>{evento.medida_nome}</td>
                    <td>{evento.planta_nome}</td>
                    <td>{evento.grupo_ativos_nome}</td>
                    <td>
                      {
                        <Link
                          to={"/ativo/" + evento.ativo_id}
                          className="text-decoration-underline"
                        >
                          {evento.ativo_nome}
                        </Link>
                      }
                    </td>
                    <td>{evento.sensor_nome}</td>
                    <td>
                      {evento.data_normalizacao ? (
                        formataData(evento.data_normalizacao)
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td>
                      <Badge
                        className={
                          "font-size-12 badge rounded-pill bg-" +
                          evento.evento_status
                        }
                        pill
                      >
                        {" "}
                        {evento.evento_status}
                      </Badge>
                    </td>
                    <td>
                      <div className="d-flex gap-3">
                        <Button
                          to="#"
                          className="btn-rounded"
                          color="light"
                          onClick={() => {
                            toggleLogDeTrabalho(evento.evento_id);
                          }}
                          disabled={
                            evento.evento_status === "Fechado" ? true : false
                          }
                        >
                          <i
                            className="bx bx-notepad font-size-14"
                            data-tip
                            data-for="tooltip-inserir-log-de-trabalho"
                          />
                        </Button>
                        <ReactTooltip
                          id="tooltip-inserir-log-de-trabalho"
                          place="top"
                          effect="solid"
                        >
                          Inserir log de trabalho
                        </ReactTooltip>

                        <Button
                          to="#"
                          className="btn-rounded"
                          color="light"
                          onClick={() => {
                            toggleResolverEvento(evento.evento_id);
                          }}
                          disabled={
                            evento.evento_status === "Fechado" ? true : false
                          }
                        >
                          <i
                            className="bx bx-check font-size-14"
                            data-tip
                            data-for="tooltip-resolver-evento"
                          />
                        </Button>
                        <ReactTooltip
                          id="tooltip-resolver-evento"
                          place="top"
                          effect="solid"
                        >
                          Resolver evento
                        </ReactTooltip>

                        {/*Botão histórico do evento*/}
                        <Link to={"/evento/" + evento.evento_id}>
                          <Button
                            type="button"
                            className="btn-rounded"
                            color="light"
                          >
                            <i
                              className="bx bx-history font-size-14"
                              data-tip
                              data-for="tooltip-historico-evento"
                            />
                            {""}
                          </Button>
                          <ReactTooltip
                            id="tooltip-historico-evento"
                            place="top"
                            effect="solid"
                          >
                            Histórico do evento
                          </ReactTooltip>
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
              <InserirLogDeTrabalhoModal
                isOpen={modalLogDeTrabalho}
                toggle={toggleLogDeTrabalho}
                eventoid={eventoid}
              />
              <ResolverEventoModal
                isOpen={modalResolverEvento}
                toggle={toggleResolverEvento}
                eventoid={eventoid}
              />
            </Table>
          </Col>

          {/* Botões de paginação */}
          <Row>
            <Col
              lg="12"
              className="pagination pagination-rounded justify-content-center mb-2"
            >
              <Pagination
                activePage={activePage}
                totalItemsCount={eventosTotal}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                itemsCountPerPage={5}
              />
            </Col>
          </Row>
        </Row>
      </Container>
    </React.Fragment>
  );
}

TabelaEventos.propTypes = {
  tabelaEventosDados: PropTypes.array,
};

export default TabelaEventos;
