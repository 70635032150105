import React from "react";
import PropTypes from "prop-types";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import FotoAtivo from "../../../../assets/images/foto-generica-ativo.jpg";

const CardAtivoInformacoes = (props) => {
  return (
    <Col lg="6" xl="6" sm="12">
      <Card>
        <CardBody>
          <Row>
            <Col lg="2" sm="2" className="mt-2 ">
              <span className=" rounded-square bg-light avatar-md">
                {props.fotoAtivo ? (
                  <img src={props.fotoAtivo} alt="" height="60" width="80" />
                ) : (
                  <img src={FotoAtivo} alt="" height="60" width="80" />
                )}
              </span>
            </Col>
            <Col lg="10" sm="10" className="mt-2 ">
              <span className="text-truncate font-size-16 text-black">
                {props.ativoNome}
              </span>
              <br />
              <span className="text-muted">{props.grupoAtivosNome}</span>
              <br />
              <Badge className="rounded bg-info bg-soft text-primary ">
                {props.tagNome}
              </Badge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

CardAtivoInformacoes.propTypes = {
  fotoAtivo: PropTypes.any,
  ativoNome: PropTypes.string,
  grupoAtivosNome: PropTypes.string,
  tagNome: PropTypes.any,
};

export default CardAtivoInformacoes;
