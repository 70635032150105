import React from "react";

import Aplication from "./routes";

// Import scss
import "./assets/scss/theme.scss";

const App = () => <Aplication />;

export default App;
