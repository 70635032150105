import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

toast.configure();

function CriarNovoGrupoUsuariosModal(props) {
  const { isOpen, toggle, setGrupos } = props;

  const [planta, setPlanta] = useState(null);
  const [novoGrupoNome, setNovoGrupoNome] = useState(null);

  function handlePlanta(planta) {
    setPlanta(planta.value);
  }

  //Listagem de Plantas
  const opcoesPlanta = (inputValue) => {
    const token = localStorage.getItem("token");
    return api
      .get(`/plantas/lista-de-plantas`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        const res = response.data.plantas.map((planta) => ({
          value: planta.planta_nome,
          label: planta.planta_nome,
        }));
        return res;
      })
      .catch((err) => {
        console.error("Ops! ocorreu um erroo: " + err);
      });
  };

  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");

    api
      .post(
        `/gerenciamento/grupos-de-usuarios`,
        {
          novoGrupoNome,
          planta,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setGrupos(response.data.grupos_usuarios.data);
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.mensagem_erro, {
          autoClose: 3000,
        });
      });
  }
  return (
    <>
      <Modal
        id="modal-criar-grupo-ativos"
        isOpen={isOpen}
        autoFocus={true}
        className="modal-dialog-centered"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader>Criar Grupo de usuários</ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <div className=" col-md-12 mb-3">
                <Label className=" text-dark">Nome do grupo</Label>
                <Input
                  name="nome"
                  type="text"
                  className="form-control"
                  onChange={(event) => setNovoGrupoNome(event.target.value)}
                />
              </div>

              <div className="col-md-12 mb-3">
                <Label className=" text-dark">Planta</Label>
                <AsyncSelect
                  className="text-dark"
                  cacheOptions
                  defaultOptions
                  name="planta"
                  onChange={handlePlanta}
                  loadOptions={opcoesPlanta}
                  placeholder="Selecione a planta"
                  classNamePrefix="select2-selection"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="link" onClick={toggle}>
                Cancelar
              </Button>
              <Button color="primary" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </form>
        </div>
      </Modal>
    </>
  );
}

CriarNovoGrupoUsuariosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  setGrupos: PropTypes.func,
};

export default CriarNovoGrupoUsuariosModal;
