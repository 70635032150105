import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from "reactstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";

toast.configure();

const ExcluirUsuarioGrupoUsuariosModal = (props) => {
  const { isOpen, toggle, grupousuariosid, usuarioid } = props;

  const [erro, setErro] = useState(null);

  // handleValidSubmit para enviar o formulário via requisição delete para micromeros-back
  function onSubmit(event) {
    event.preventDefault();
    const token = localStorage.getItem("token");

    api
      .delete(
        `/gerenciamento/grupos-de-usuarios/${grupousuariosid}/usuarios/${usuarioid}`,
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        toggle();
        toast.success(response.data.mensagem_sucesso, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        if (error.response.data.mensagem_erro) {
          setErro(error.response.data.mensagem_erro);
        }
      });
  }

  return (
    <Modal
      id="remover-usuario-grupo-usuarios"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-dialog-centered"
      toggle={toggle}
    >
      <Container fluid={true}>
        <ModalHeader>Remover usuário do Grupo de usuários</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <p>
              Você tem certeza que deseja remover o usuário selecionado deste
              grupo de usuários?
            </p>
            <span className="text-danger">{erro ? erro : null}</span>
          </ModalBody>
          <Row>
            <Col xs={12}>
              <div className="modal-footer">
                <Button color="link" type="button" onClick={toggle}>
                  Cancelar
                </Button>
                <button type="submit" className="btn btn-primary btn-block">
                  Confirmar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
};

ExcluirUsuarioGrupoUsuariosModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  grupousuariosid: PropTypes.any,
  usuarioid: PropTypes.any,
};

export default ExcluirUsuarioGrupoUsuariosModal;
